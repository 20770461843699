import React from 'react';

const Quote = ({ project }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Quote
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {project ? (<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dt className="text-xs font-medium text-gray-500">Clearance quote/License</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.clearance_quote}</dd>
            </div>
        </dl>)
        : <p className="text-gray-500">Quote information not available.</p>}
      </div>
    </div>
  </section>);
}

export default Quote;
