import React from 'react'
import { MusicalNoteIcon, FolderIcon, NewspaperIcon, CloudIcon } from '@heroicons/react/24/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ActionGrid = () => {

  const actions = [
    {
      icon: MusicalNoteIcon,
      name: 'Create Sync License',
      href: '/licenses/synchronization/add/',
      prompt: 'Add a new Sync License.'
    },
    {
      icon: FolderIcon,
      name: 'Create Mech License',
      href: '/licenses/mechanical/add/',
      prompt: 'Add a new Mech License.'
    },
    {
      icon: NewspaperIcon,
      name: 'View Sync Licenses',
      href: '/licenses/synchronization/',
      prompt: 'View all Sync Licenses.'
    },
    {
      icon: CloudIcon,
      name: 'View Mech Licenses',
      href: '/licenses/mechanical/',
      prompt: 'View all Mech Licenses.'
    }
  ]

  return (<>
    <div className="mb-2 mt-5 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text:2xl sm:truncate">Create Licenses</h2>
        </div>
    </div>
    {/* Main 3 column grid */}
    <section className='mt-5 rounded-md shadow' aria-labelledby="quick-links-title">
        <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        <h2 className="sr-only" id="quick-links-title">
            Quick links
        </h2>
        {actions.map((action, actionIdx) => (
            <div
            key={action.name}
            className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'relative group bg-white p-6 hover:bg-indigo-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
            >
            <div>
                <span
                className='text-indigo-700 bg-indigo-50 rounded-lg inline-flex p-3 ring-4 ring-white'
                >
                <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div className="mt-8">
                <h3 className="text-lg font-medium">
                <a href={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.name}
                </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                {action.prompt}
                </p>
            </div>
            <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
            >
                <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
            </div>
        ))}
        </div>
    </section>
  </>)
}

export default ActionGrid
