import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { TrashIcon, ArrowRightOnRectangleIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline'

import { AuthContext } from "../../../stores/auth";

const Header = () => {
  const history = useHistory()
  const location = useLocation()
  const authStore = useContext(AuthContext)
  const [assetType, setAssetType] = useState('compositions')
  const [searchValue, setSearchValue] = useState()
  const [searchField, setSearchField] = useState()
  const [filterType, setFilterType] = useState()
  const [filterField, setFilterField] = useState()

  useEffect(() => {
    if (location.pathname.split('/')[1] !== '' && location.pathname.split('/')[1] !== 'dashboard') {
      setAssetType(location.pathname.split('/')[1])
    }

  }, [location.pathname])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  const _keyUp = (event) => {
    if (event.keyCode === 13) {
      let query = {
        page: 1,
        q: searchValue
      }

      if (searchValue === undefined || searchValue === '') {
        query = { page: 1 }
      }
      history.push({
        pathname: `/${assetType}`,
        search: `?${objectToQueryString(query)}`
      })
    }
  }

  const handleChange = (e) => {
    setAssetType(e.target.value)
    setSearchValue('')
  }

  const handleAdvancedSearch = () => {
    let query = {
      page: 1,
      q: searchField
    }
    if (filterType !== null && filterType !== undefined) {
      let filterQ = filterType + '|' + filterField
      query['f'] = filterQ
    }
    history.push({
      pathname: `/${assetType}`,
      search: `?${objectToQueryString(query)}`
    })
  }

  const resetAdvancedSearch = () => {
    setSearchField('')
    setFilterType('')
    setFilterField('')
  }

  const placeHolders = {
    'compositions': 'Search through a list of Compositions',
    'recordings': 'Search through a list of Recordings',
    'writers': 'Search through a list of Writers',
    'publishers': 'Search through a list of Publishers',
    'artists': 'Search through a list of Artists',
    'record-labels': 'Search through a list of Record Labels'
  }

  const options = {
    'compositions': {
      'writers': 'Writer',
      'owners': 'Owner',
      'publishers': 'Collector'
    },
    'recordings': {
      'isrcs': 'IDs',
      'artists': 'Artist'
    },
    'writers': {
      'pro_affiliation': 'Pro Affiliation',
      'pub_affiliations': 'Publisher',
      'ipi_number': 'IPI Number',
      'isni': 'ISNI'
    },
    'publishers': {
      'pro_affiliation': 'Pro Affiliation',
      'ipi_number': 'IPI Number'
    },
    'artists': {
      'record_label_affiliation': 'Record Label'
    },
    'record-labels': {
      'pk': 'ID'
    }
  }

  return (<>
    <Disclosure as="nav" className="flex-shrink-0 bg-white">
      {({ open }) => (
        <>
          <div className="w-full relative flex items-center justify-between h-16 border-b">
            {/* Search section */}
            <div className="flex-1">
              {/* <div className="px-2 lg:px-6">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="assetType" className="sr-only">
                      Asset Type
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      id="assetType"
                      name="assetType"
                      autoComplete="assetType"
                      defaultValue={location.pathname.split('/')[1]}
                      className="focus:ring-transparent focus:border-red-500 h-full py-0 pl-3 pr-7 border-transparent bg-gray-100 text-gray-500 sm:text-sm rounded-md"
                    >
                      <option value="compositions">Composition</option>
                      <option value="recordings">Recording</option>
                      <option value="writers">Writer</option>
                      <option value="publishers">Publisher</option>
                      <option value="artists">Artist</option>
                      <option value="record-labels">Record Label</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyUp={(event) => _keyUp(event)}
                    className="focus:ring-red-500 focus:border-red-500 disabled:bg-gray-200 block w-full pl-36 sm:text-sm border-gray-200 rounded-md"
                    placeholder={placeHolders[assetType]}
                  />
                </div>
              </div> */}
            </div>
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group mt-1 bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                    )}
                  >
                    <span><AdjustmentsVerticalIcon className="h-9 w-9 rounded-md bg-red-600 text-white p-1 hover:bg-red-700" aria-hidden="true" /></span>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 transform -translate-x-3/4 mt-3 px-2 w-third-screen sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative w-full bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          <div className="overflow-hidden divide-y divide-gray-200">
                            <div className="p-6">
                              <div className="pb-2 border-b border-gray-200">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Advanced Search</h3>
                              </div>
                              <div className='py-4'>
                                <div className="grid grid-cols-1 gap-y-6 gap-x-4">
                                  <div className="">
                                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                      {placeHolders[assetType] || 'Search'}
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        type='text'
                                        name="query"
                                        placeholder="Separate each query with a | (ex: Title 1|Title 2)"
                                        value={searchField}
                                        onChange={(e) => setSearchField(e.target.value)}
                                        rows={3}
                                        className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                        defaultValue={''}
                                      />
                                    </div>
                                  </div>

                                  {options[assetType] && <div className="">
                                    <fieldset className="">
                                      <legend className="block text-sm font-medium text-gray-700">Filter</legend>
                                      <div className="mt-1 rounded-md shadow-sm -space-y-px">
                                        <div>
                                          <label htmlFor="filter" className="sr-only">
                                            Filter
                                          </label>
                                          <select
                                            id="filter"
                                            name="filter"
                                            value={filterType}
                                            onChange={(e) => setFilterType(e.target.value)}
                                            className="focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                          >
                                            <option value='-1'>Select...</option>
                                            {Object.keys(options[assetType]).map((key, index) => (
                                              <option key={index} value={key}>{options[assetType][key]}</option>
                                            ))}
                                          </select>
                                        </div>
                                        <div>
                                          <label htmlFor="filter-field" className="sr-only">
                                            Filter Field
                                          </label>
                                          <input
                                            type="text"
                                            name="filter-field"
                                            id="postal-code"
                                            value={filterField}
                                            onChange={(e) => setFilterField(e.target.value)}
                                            className="focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>}

                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                      onClick={() => resetAdvancedSearch()}
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                      onClick={() => handleAdvancedSearch()}
                                    >
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
            <div className="hidden lg:block flex flex-grow-0 justify-end">
              <div className="py-2 px-6">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                  onClick={() => authStore.logout()}
                >
                  Logout
                  <ArrowRightOnRectangleIcon className="ml-2 -mr-1 h-4 w-4" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  </>);
}

export default Header;
