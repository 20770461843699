import React, { useState, useContext } from 'react'
import { GoogleLogin } from 'react-google-login'

import { AuthContext } from 'stores/auth'
import { googleLogin } from 'api/auth'

const GoogleButton = (props) => {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const responseGoogleSuccess = async (response) => {
    setLoading(true);
    await googleLogin(response.tokenId).then(res => {
      authStore.oauthLogin(res.data);
      setLoading(false);
    }).catch(err => {
      console.log(err.response);
      setLoading(false);
    });
  };

  const responseGoogleFailure = (response) => {
    console.log('Google Login Error', response);
  };

  return (<GoogleLogin
    {...props}
    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
    type="button"
    className="w-full flex items-center justify-center px-4 py-4 border border-gray-300 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    onSuccess={responseGoogleSuccess}
    onFailure={responseGoogleFailure}
  >
    <span>{props.label ? props.label : 'Continue with Google'}</span>
  </GoogleLogin>);
};

export default GoogleButton;
