import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import FormLabel from '../../../../components/Form/FormLabel';
import FormError from '../../../../components/Form/FormError';
import DatePicker from '../../../../components/Form/DatePicker';
import { classNames } from 'components/utils';

const FormatsOfReleases = [
  { label: 'Digital Download', value: 'digital_download' },
  { label: 'Streaming', value: 'streaming' },
  { label: 'Vinyl', value: 'vinyl' },
  { label: 'CD', value: 'cd' },
  { label: 'Cassette', value: 'cassette' },
  { label: '8-Track', value: '8_track' },
  { label: 'Other', value: 'other' }
];

const ReleaseForm = ({ saveForLater, baseInputStyle }) => {
  const methods = useFormContext();
  const { register, setValue, formState, setError, clearErrors, getValues, watch } = methods;
  const [showOtherFormatField, setShowOtherFormatField] = useState(false)

  const watchFormat = watch('release.format');
  const watchPlatform = watch('release.other_platform');

  const updateFormats = () => {
    if (watchFormat) {
      const selectedFormats = Object.keys(watchFormat)?.filter((key, val) => {
        return watchFormat[key];
      });
      setValue('release.formats', selectedFormats?.join(','));
      if (selectedFormats.includes('other')) {
        setShowOtherFormatField(true)
      } else {
        setShowOtherFormatField(false)
        setValue('release.other_format', '');
      }
    }
  }

  useEffect(() => {
    updateFormats();
  }, [watchFormat]);

  const handleCheckbox = (evt, name) => {
    const val = getValues(name);
    setValue(name, !val);
  };

  const handleFormatChange = (evt) => {
    const name = evt?.target?.name;
    const checked = evt?.target?.checked;
    setValue(name, checked);
    updateFormats();
  };

  const handleRecordingLength = (evt, name) => {
    const val = evt?.target?.value;
    const length_mins = name === 'minutes' ? val : getValues('release.recording_length_minutes');
    const length_secs = name === 'seconds' ? val : getValues('release.recording_length_seconds');

    const recordingLength = (parseInt(length_mins) * 60) + parseInt(length_secs);
    setValue('release.recording_length', recordingLength);
  };

  return (<div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
    <div>
      <h3 className="text-xl font-medium leading-6 text-gray-900">Release Information</h3>
    </div>
    <div className="mt-5">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-4">
          <FormLabel label='Release Title' name='release.title' required />
          <input
            type="text"
            name="release.title"
            id="release.title"
            className={baseInputStyle}
            {...register("release.title", { required: !saveForLater })}
          />
          <FormError name='release.title' />
        </div>

        <div className="col-span-2">
          <FormLabel label='Recording Artist' name='release.recording_artist' required />
          <input
            type="text"
            name="release.recording_artist"
            id="release.recording_artist"
            className={baseInputStyle}
            {...register("release.recording_artist", { required: !saveForLater })}
          />
          <FormError name='release.recording_artist' />
        </div>

        <div className="col-span-2">
          <FormLabel label='Project Code' name='project_code' required />
          <input
            type="text"
            name="release.project_code"
            id="release.project_code"
            className={baseInputStyle}
            {...register("release.project_code", { required: !saveForLater })}
          />
          <FormError name='release.project_code' />
        </div>

        <div className="col-span-2">
          <FormLabel label='Record Label' name='release.record_label' required />
          <input
            type="text"
            name="release.record_label"
            id="release.record_label"
            className={baseInputStyle}
            {...register("release.record_label", { required: !saveForLater })}
          />
          <FormError name='release.record_label' />
        </div>

        <div className="col-span-2">
          <FormLabel label='Distributor' name='release.distributor' required />
          <input
            type="text"
            name="release.distributor"
            id="release.distributor"
            className={baseInputStyle}
            {...register("release.distributor", { required: !saveForLater })}
          />
          <FormError name='release.distributor' />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <FormLabel label='ISRC' name='release.isrc' />
          <input
            type="text"
            name="release.isrc"
            id="release.isrc"
            className={baseInputStyle}
            {...register("release.isrc")}
          />
          <FormError name='release.isrc' />
        </div>

        <div className="col-span-2">
          <FormLabel label='UPC' name='release.upc' />
          <input
            type="text"
            name="release.upc"
            id="release.upc"
            className={baseInputStyle}
            {...register("release.upc")}
          />
          <FormError name='release.upc' />
        </div>

        <div className="col-span-2">
          <FormLabel label='Length of Recording' name='release.recording_length' />
          <input type="hidden" {...register('release.recording_length')} />
          <div className="mt-1 flex">
            <div className="w-1/2 flex-1 min-w-0">
              <input
                type="number"
                min="0"
                step="any"
                name="release.recording_length_minutes"
                id="release.recording_length_minutes"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-bl-md rounded-tl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="minutes"
                {...register('release.recording_length_minutes')}
                onChange={(evt) => handleRecordingLength(evt, 'minutes')}
              />
            </div>
            <div className="flex-1 min-w-0">
              <input
                type="number"
                min="0"
                step="any"
                name="release.recording_length_seconds"
                id="release.recording_length_seconds"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-br-md rounded-tr-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="seconds"
                {...register('release.recording_length_seconds')}
                onChange={(evt) => handleRecordingLength(evt, 'seconds')}
              />
            </div>
          </div>
          <FormError name='release.recording_length' />
        </div>

        <div className="col-span-3">
          <FormLabel label='Release Date' name='release.release_date' required />
          <input type="hidden" {...register('release.release_date', { required: !saveForLater })} />
          <DatePicker
            name={'release.release_date'}
            methods={methods}
            future={5}
            required
          />
          <FormError name='release_date' />
        </div>

        <div className="col-span-3">
          <FormLabel label='Release Type' name='release.release_type' required />
          <select
            id="release.release_type"
            name="release.release_type"
            defaultValue=""
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            {...register('release.release_type', { required: !saveForLater })}
          >
            <option value="" disabled>----</option>
            <option value="album">Album</option>
            <option value="single">Single</option>
            <option value="ep">EP</option>
            <option value="other">Other</option>
          </select>
          <FormError name='release.release_type' />
        </div>

        <div className="col-span-3">
          <FormLabel label='Territory of Release' name='release.release_territory' required />
          <input
            type="text"
            name="release.release_territory"
            className={baseInputStyle}
            {...register('release.release_territory', { required: !saveForLater })}
          />
          <FormError name='release.release_territory' />
        </div>

        <div className="col-span-3">
          <FormLabel label='Platforms of Release' name='release.other_platform' required />
          <input
            type="text"
            name="release.other_platform"
            id="release.other_platform"
            className={baseInputStyle}
            {...register("release.other_platform", { required: !saveForLater })}
          />
          <FormError name='release.other_platform' />
        </div>

        <div className="col-span-6">
          <FormLabel label='Formats of Release' name='release.formatObj' required />
          <FormError name='release.formats' />
          <input type="hidden" {...register('release.formats', { required: !saveForLater })} />
          <fieldset>
            <div className="mt-4 grid grid-cols-4">
              <div className="col-span-3 grid grid-cols-3 gap-4">
                {FormatsOfReleases.map(option => {
                  const key = 'release.format.' + option.value;
                  return (<div className="flex items-start" key={key}>
                    <div className="h-5 flex items-center">
                      <input
                        id={key}
                        name={key}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        {...register(key)}
                        onChange={(evt) => handleFormatChange(evt)}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor={key} className="font-normal text-gray-700">
                        {option.label}
                      </label>
                    </div>
                  </div>)
                })}
              </div>
              {showOtherFormatField &&
                <div className='col-span-3'>
                  <textarea
                    id="release.other_format"
                    name="release.other_format"
                    rows={2}
                    className="mt-3 shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300"
                    {...register('release.other_format', { required: !saveForLater })}
                  />
                  <FormError name='release.other_format' />
                </div>
              }
            </div>
          </fieldset>
        </div>

        <div className="col-span-3">
          <FormLabel label='Manufacture Date' name='release.manufacture_date' required />
          <input type="hidden" {...register('release.manufacture_date', { required: !saveForLater })} />
          <DatePicker
            future={5}
            name={'release.manufacture_date'}
            methods={methods}
            required
          />
          <FormError name='release.manufacture_date' />
        </div>

        {watchPlatform?.toLowerCase() === 'physical' &&
          <div className="col-span-4">
            <FormLabel label='If Physical Release, how many are being created:' name='release.physical_units' required />
            <input
              type="number"
              name="release.physical_units"
              id="release.physical_units"
              className={classNames('max-w-xs', baseInputStyle)}
              {...register('release.physical_units', { required: !saveForLater })}
            />
            <FormError name='release.physical_units' />
          </div>
        }
      </div>
    </div>
  </div>);
}

export default ReleaseForm;
