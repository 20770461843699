import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';

import LogInForm from './components/Form';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import GoogleButton from '../../../components/Buttons/GoogleButton';

function LogIn () {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    if (authStore.loggedIn) {
      return (<Redirect to='/' />)
    } else {
      return (<div className="min-h-full min-w-full flex h-screen">
        <div className="flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex items-center">
                <img
                  className="h-4 w-auto inline-block"
                  src="/assets/images/Logo.png"
                  alt="Exploration"
                />
                <h1 className="text-lg font-extrabold text-gray-900 inline-block align-middle mx-2">Exploration</h1>
              </div>
              <h2 className="text-3xl font-extrabold text-gray-900 inline-block align-middle mt-1">Licensing Portal</h2>

              {/* <div className="mt-6">
                <form onSubmit={() => {}} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                    <div className="mt-1">
                      <input
                      id="email" name="email" type="email" autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <div className="mt-1">
                      <input
                      id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link to="#" className="font-medium text-red-600 hover:text-red-500">Forgot your password?</Link>
                    </div>
                  </div>

                  <div>
                    <button type="submit" onClick={() => {}} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-800 hover:from-red-800 hover:to-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Sign in</button>
                  </div>
                </form>
              </div>
              <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-100 text-gray-500"> OR </span>
                </div>
              </div> */}

              <div className="mt-6">
                <GoogleButton />
              </div>
              <p className="mt-6 text-sm">
                <span className="font-medium text-gray-400 hover:text-gray-500">
                  By signing in, you agree to Exploration's Terms & Conditions and Privacy Policy.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-no-repeat bg-center bg-cover" style={ { backgroundImage: `url("https://source.unsplash.com/AJ-YiPjnFiw")` } }>
          <div className="absolute inset-0 opacity-90 bg-gradient-to-bl from-indigo-600 to-indigo-400" />
        </div>
      </div>)
    }
  })
}

export default LogIn
