import { XMarkIcon } from "@heroicons/react/24/solid"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"

const VALID_FILE_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "jpg",
  "png",
]

const Attachments = () => {
  const { getValues, setValues } = useFormContext()
  const [files, setFiles] = useState([])
  const { setValue } = useFormContext()
  let inputFile = useRef(null)

  const fileHandler = (e) => {
    e.preventDefault()
    const tempFiles = e.target.files
    const length = e.target.files.length
    const arr = [...Array(length)].map((_, i) => i)
    let valid = true
    arr.forEach((i) => {
      const extension = e.target.files[i].name.split(".").at(-1)
      if (!VALID_FILE_EXTENSIONS.includes(extension)) {
        valid = false
      }
    })
    if (valid) {
      arr.forEach((i) => {
        setFiles((prevState) => [
          ...prevState,
          { file: tempFiles[i], uuid: uuidv4() },
        ])
      })
    }
  }

  const remove = (uuid) => {
    setFiles((prevState) => prevState.filter((f) => f.uuid !== uuid))
  }

  useEffect(() => {
    setValue(
      "attachments",
      files.map((f) => f.file)
    )
  }, [files])

  useEffect(() => {
    const attachments = getValues("attachments")
    attachments &&
      attachments.length > 0 &&
      setFiles(
        attachments.map((_attachment) => ({
          file: {
            ..._attachment,
            name: _attachment.file_name,
          },
          uuid: uuidv4(),
        }))
      )
  }, [])

  return (
    <div className="bg-white shadow px-4 py-5 rounded-lg space-y-1 sm:p-6">
      <h3 className="text-xl font-medium leading-6 text-gray-900">
        Attachments
      </h3>
      <div>
        <ul
          role="list"
          className="divide-y divide-gray-200 rounded-md border border-gray-200"
        >
          {files.map((f) => (
            <li
              key={f.uuid}
              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              {f.file.name}{" "}
              <div onClick={() => remove(f.uuid)}>
                <XMarkIcon className="h-5 w-5 cursor-pointer hover:text-red-500" />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <button
          type="button"
          className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => inputFile.click()}
        >
          Upload
        </button>
        <input
          ref={(input) => (inputFile = input)}
          type="file"
          multiple
          onChange={fileHandler}
          className="hidden"
        />
      </div>
    </div>
  )
}

export default Attachments
