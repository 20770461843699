import React, { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { togglePaymentSynchronizationLicense } from 'api/core';

import AttachmentModal from './AttachmentModal'

const Header = ({ license, setLicense }) => {

  const [showModal, setShowModal] = useState(false)

  const hasSong = license?.right_type === 'publishing' || license?.right_type === 'both'
  const hasRecording = license?.right_type === 'sound_recording' || license?.right_type === 'both'

  const onAddFile = e => {
    e.preventDefault()
    setShowModal(true)
  }

  const togglePayment = () => {
    togglePaymentSynchronizationLicense(license.id)
    .then(res=>{
      setLicense(res.data)
    })
    .catch(res=>{
      // TODO: redo error logging
      console.log(res)
    })
  }

  return (<>

    <AttachmentModal show={showModal} setShow={setShowModal} setLicense={setLicense} />

    <div className="md:flex md:items-center md:justify-between md:space-x-5 sm:px-6">
      <div className="flex items-start space-x-5">
        <div className="pt-1.5 flex items-center">
          <h1 className="text-2xl font-bold text-gray-900">SYNC-{license.license_id}</h1>
          <div className="ml-4">
            {hasSong && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
              Publishing
            </span>)}
            {hasRecording && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
              Sound Recording
            </span>)}
            {license.payment_received
              ? (<span className="ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 cursor-pointer"
                  onClick={() => togglePayment()}>
                <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Payment Received
              </span>)
              : (<span className="ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 cursor-pointer"
                onClick={() => togglePayment()}>
                <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Payment Not Received
              </span>)
            }
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <Link
          to={`/licenses/synchronization/${license.id}/edit`}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={onAddFile}
        >
          <PaperClipIcon className="mr-2 flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          Attach File
        </button>
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          Generate
        </button>
      </div>
    </div>
  </>);
}

export default Header;
