import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useObserver } from 'mobx-react';
import InfoBar from 'components/InfoBar';

import { AuthContext } from 'stores/auth';
import MechanicalLicenses from 'routes/Mechanical/List/components/MechanicalLicenses';

const List = () => {
  const authStore = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [searchQ, setSearchQ] = useState(query.q || '')

  const onClose = () => {
    history.replace({
      pathname: `/licenses/mechanical/`,
    })
    setSearchQ('')
  }

  return useObserver(() => {
    return (<>
      {searchQ && 
        <InfoBar
          text={`Showing search results for ${searchQ}`}
          onClose={onClose}
          showClose
        />
      }
      <MechanicalLicenses searchQ={searchQ} />
    </>)
  });
}

export default List
