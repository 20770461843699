import React from 'react';
import { useObserver } from 'mobx-react';

import SearchBox from './components/SearchBox';
import LicenseStats from './components/LicenseStats';
import ActionGrid from './components/ActionGrid';

const Dashboard = () => {

  return useObserver(() => {
    return (<>
      <div className="min-h-full">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-8">
          <h1 className="sr-only">Dashboard</h1>
          <SearchBox />
          <section className='mt-5' aria-labelledby="quick-links-title">
            <LicenseStats />
            <ActionGrid />
          </section>
        </div>
      </div>
    </>)
  });
}

export default Dashboard
