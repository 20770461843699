import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { WRITER_DEFAULT_VALUES } from '../../../../components/const';
import FormLabel from '../../../../components/Form/FormLabel';
import ControlledWriterForm from './ControlledWriterForm';

const baseInputStyle = "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"

const RenderWriters = ({ saveForLater, songIndex, songWriters }) => {
  const methods = useFormContext();
  const { control } = methods;

  const writerMethods = useFieldArray({
    control,
    name: `songs[${songIndex}].controlled_writers`,
    keyName: 'uuid'
  })

  useEffect(() => {
    if (songWriters[songIndex]) {
      writerMethods.replace([])
      writerMethods.remove(0)
      let controlledWriters = songWriters[songIndex]
      if (controlledWriters && controlledWriters?.length > 0) {
        for (let songWriter of controlledWriters) {
          writerMethods.append(songWriter)
        }
      }
    }
  }, [songWriters])

  return (<>
    <div className="col-span-12">
        <FormLabel label='Controlled Writers' name={`songs[${songIndex}].controlled_writers`} />
        {writerMethods.fields?.map((writer, index) => (
            <ControlledWriterForm
              saveForLater={saveForLater}
              baseInputStyle={baseInputStyle}
              songIndex={songIndex}
              index={index}
              writer={writer}
              remove={writerMethods.remove}
              append={writerMethods.append}
              fields={writerMethods.fields}
            />)
        )}
        <div className='flex justify-end mt-6'>
        <button
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type='button'
            onClick={() => writerMethods.append(WRITER_DEFAULT_VALUES)}
        >
            Add Writer
        </button>
        </div> 
    </div>
  </>);
}

export default RenderWriters;
