import React, { useState } from 'react';
import { DocumentIcon, DocumentArrowDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import DeleteAttachmentModal from "./DeleteAttachmentModal"
import AttachmentQuickview from './AttachmentQuickview';
import { getLicenseAttachment, downloadLicenseAttachment } from 'api/core';

const FileInline = ({ attachment, setLicense }) => {
  const [showModal, setShowModal] = useState(false)
  const [showFile, setShowFile] = useState(false)
  const [file, setFile] = useState()

  const onView = (e) => {
    e.preventDefault()
    getLicenseAttachment(attachment.id).then(response => {
      setFile(response.data)
      setShowFile(true)
    })
    .catch(res=>{
      // TODO: redo error logging
      console.log(res)
    });
  }

  const onDownload = (e) => {
    e.preventDefault()
    downloadLicenseAttachment(attachment.id).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a")
      link.href = url
      link.setAttribute('download', attachment.file_name)
      document.body.appendChild(link);
      link.click()
      link.remove()
    })
    .catch(res=>{
      // TODO: redo error logging
      console.log(res)
    });
  }

  return (
    <li
      className="px-6 py-3 flex items-center justify-between text-sm"
    >

      <DeleteAttachmentModal show={showModal} setShow={setShowModal} attachment={attachment} setLicense={setLicense} />
      <AttachmentQuickview open={showFile} setOpen={setShowFile} file={file} />

      <div className="w-0 flex-1 flex items-center">
        <div className="flex flex-1 cursor-pointer hover:text-indigo-500" onClick={onView}>
          <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className="ml-2 flex-1 w-0 truncate">{attachment.file_name}</span>
        </div>
        <DocumentArrowDownIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400 mr-2 cursor-pointer hover:text-indigo-500"
          aria-hidden="true"
          onClick={onDownload}
        />
        <TrashIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400 cursor-pointer hover:text-red-500"
          aria-hidden="true"
          onClick={()=>setShowModal(true)}
        />
      </div>
    </li>
  )
}

const Files = ({ license, setLicense }) => {
  return (<div className="bg-white shadow sm:rounded-lg">
  <div className="px-4 py-5 sm:px-6">
    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
      Attachments
    </h2>
  </div>
  <div className="text-sm text-gray-900">
    <ul className="border border-gray-200 border-l-0 border-r-0 divide-y divide-gray-200">
      {license.attachments.map((attachment) => (
        <FileInline attachment={attachment} key={attachment.id} setLicense={setLicense} />
      ))}
    </ul>
  </div>
</div>);
}

export default Files;
