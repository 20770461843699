import React from 'react';

const Notes = ({ notes }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Notes
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6 whitespace-pre-wrap">
        {notes.content}
      </div>
    </div>
  </section>);
}

export default Notes;
