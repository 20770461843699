import styled from 'styled-components';

const Spacer = styled.div`
  height: ${(props) => props.height || '20px'};
  width: 100%;
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function timeToMinutesAndSeconds(time) { // time in seconds
  const minutes = Math.floor(time / 60);
  const seconds = time - (minutes * 60);

  return { minutes, seconds }
}

export { Spacer, classNames, timeToMinutesAndSeconds }
