import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Element } from 'react-scroll';

import { classNames } from '../../../../components/utils';
import FormLabel from '../../../../components/Form/FormLabel';
import FormError from '../../../../components/Form/FormError';
import SongForm from './SongForm';
import RecordingForm from './RecordingForm';
import ProjectInformation from './ProjectInformation';
import LicenseeForm from './LicenseeForm';
import LicenseTerms from './LicenseTerms';
import Quote from './Quote';
import Attachments from './Attachments';

const baseInputStyle = "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
const readOnlyInputStyle = "mt-1 block w-full bg-gray-200 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-0 focus:border-gray-300"

const Form = ({ saveForLater, songWriters }) => {
  const methods = useFormContext();
  const { register, watch, formState: { isDirty, isValid } } = methods;
  const rightType = watch('right_type')

  return (<>
    <div className="mt-5 space-y-6">
      <Element name='song-information'>
        <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-6">
            <div className="grid grid-cols-9 gap-6">
              <div className="grid col-span-6 grid-cols-3 gap-6">
                <div className="col-span-3">
                  <FormLabel label='License ID' name='license_id' required />
                  <div className="mt-1 rounded-md shadow-sm flex">
                    <span className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                      SYNC-
                    </span>
                    <input
                      type="text"
                      name="license_id"
                      id="license_id"
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="####"
                      {...register("license_id", { required: !saveForLater })}
                    />
                  </div>
                  <FormError name='license_id' />
                </div>
              </div>
              <div className="grid col-span-3 grid-cols-3 gap-6">
                <div className="col-span-3">
                  <FormLabel label='Right Type' name='right_type' required />
                  <div className="flex rounded-md shadow-sm">
                    <select
                      id="right_type"
                      name="right_type"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("right_type", { required: !saveForLater })}
                    >
                      <option value='publishing'>Publishing</option>
                      <option value='sound_recording'>Sound Recording</option>
                      <option value='both'>Both</option>
                    </select>
                  </div>
                  <FormError name='right_type' />
                </div>
              </div>
            </div>

            <SongForm saveForLater={saveForLater} required={((rightType === 'publishing' || rightType === 'both') && !saveForLater)} songWriters={songWriters} />
            <RecordingForm required={((rightType === 'sound_recording' || rightType === 'both') && !saveForLater)}/>
          </div>
        </div>
      </Element>

      <Element name='project-information'>
        <ProjectInformation
          saveForLater={saveForLater}
          baseInputStyle={baseInputStyle}
        />
      </Element>

      <Element name='project-information'>
        <Quote
          saveForLater={saveForLater}
          baseInputStyle={baseInputStyle}
        />
      </Element>

      <Element name='licensee-information'>
        <LicenseeForm
          saveForLater={saveForLater}
          register={register}
          baseInputStyle={baseInputStyle}
        />
      </Element>

      <Element name='license-terms'>
        <LicenseTerms
          saveForLater={saveForLater}
          register={register}
          baseInputStyle={baseInputStyle}
        />
      </Element>

      <Element>
        <Attachments />
      </Element>

      <Element name='notes'>
        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
          <div>
            <h3 className="text-xl font-medium leading-6 text-gray-900">Notes</h3>
          </div>
          <div className="mt-3">
            <div>
              <textarea
                id="notes.content"
                name="notes.content"
                rows={5}
                className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                {...register("notes.content")}
              />
            </div>
          </div>
        </div>
      </Element>
    </div>
  </>);
}

export default Form;
