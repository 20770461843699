import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { AuthContext } from 'stores/auth'
import { Spacer } from 'components/utils'

function LoginForm () {
  const authStore = useContext(AuthContext);
  const { register, handleSubmit, errors, setError, clearErrors, formState } = useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    await authStore.login({...data}).then(() => {
      clearErrors(['email', 'login'])
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      switch (err.status) {
        case 403:
          setError('login', {
            type: 'manual',
            message: 'Please click on the link that has been sent to your email account to verify your email.'
          })
          break

        default:
          setError('login', {
            type: 'manual',
            message: 'Invalid email/password'
          })
          break
      }
    })
  }

  const handleInputChange = () => {
    if (formState.isSubmitted && formState.touched) {
      clearErrors(['email', 'login'])
    }
  }

  return (<div>
    <h2>Login</h2>
    <Spacer height={'30px'} />

    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div controlId="formEmail">
        <label>Email</label>
        <input
          name="email"
          type="email"
          ref={register({ required: true })}
          onChange={handleInputChange}
        />
      </div>
      <div controlId="password">
        <label>Password</label>
        <input
          name="password"
          type='password'
          ref={register({ required: true })}
          onChange={handleInputChange}
        />
      </div>

      <Spacer height={'10px'} />

      <div>
        <Link to="/password/forgot/">
          <button variant="outline" type="button">Forgot</button>
        </Link>

        <button
          variant="secondary"
          type="submit"
          disabled={loading}
        >
          {!loading
            ? 'Login'
            : 'Logged In'
          }
        </button>
      </div>
    </form>
  </div>)
}

export default LoginForm
