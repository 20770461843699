import React, { useEffect, useState } from 'react';
import { getYear, getMonth, getDate, isValid, parse, format, getDaysInMonth } from 'date-fns'
import _ from 'lodash';

import { classNames } from '../utils';

const Months = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

const DatePicker = ({ name, methods, required, future }) => {
  const { register, formState, getValues, setValue, setError, clearErrors } = methods;
  const { errors } = formState;
  const fieldError = errors?.[name];

  const dateToday = new Date();
  const [maxYear, setMaxyear] = useState(future ? getYear(dateToday) + future : getYear(dateToday));

  const [year, setYear] = useState(getYear(dateToday));
  const [month, setMonth] = useState(getMonth(dateToday) + 1);
  const [day, setDay] = useState(getDate(dateToday));
  const [days, setDays] = useState(30);

  useEffect(() => {
    const val = getValues(name);
    if (val) {
      const dt = parse(val, 'M/d/yyyy', new Date());
      setYear(getYear(dt));
      setMonth(getMonth(dt) + 1);
      setDay(getDate(dt));
    };
  }, []);

  useEffect(() => {
    const result = getDaysInMonth(new Date(year, month - 1));
    setDays(result);
    if (day > result) {
      setDay(1);
    };
  }, [year, month]);

  useEffect(() => {
    const dt = parse(month + '/' + day + '/' + year, 'M/d/yyyy', new Date());
    if (!isValid(dt)) {
      setError(name, {
        type: "manual",
        message: "Invalid date",
      });
    } else {
      clearErrors([name]);
      setValue(name, format(dt, 'M/d/yyyy'));
    }
  }, [year, month, day]);

  const handleChange = (evt, source) => {
    const val = evt?.target?.value;

    if (source === 'year') {
      setYear(parseInt(val));
    } else if (source === 'month') {
      setMonth(parseInt(val));
    } else if (source === 'day') {
      setDay(parseInt(val));
    };
  };

  return (<div
    className={classNames(
      fieldError
        ? 'border-red-300'
        : 'border-gray-300',
      'mt-1 border rounded-md'
    )}
  >
    <input type="hidden" {...register(name, { required: required })} />
    <div className="grid grid-cols-12 gap-0">
      <select
        value={month}
        onChange={(evt) => handleChange(evt, 'month')}
        className="col-span-5 block w-full pl-3 pr-10 py-2 text-base border-0 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-none rounded-tl-md rounded-bl-md border-r-0"
      >
        {Months.map(opt => (
          <option
            key={opt.value}
            value={opt.value}
          >
            {opt.label}
          </option>
        ))}
      </select>
      <select
        value={day}
        onChange={(evt) => handleChange(evt, 'day')}
        className="col-span-3 block w-full pl-3 pr-10 py-2 text-base border-0 border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-none"
      >
        {_.range(1, days + 1).map(opt => (
          <option
            key={opt}
            value={opt}
          >
            {opt}
          </option>
        ))}
      </select>
      <select
        value={year}
        onChange={(evt) => handleChange(evt, 'year')}
        className="col-span-4 block w-full pl-3 pr-10 py-2 text-base border-0 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-none rounded-tr-md rounded-br-md border-l-0"
      >
        {_.range(1970, maxYear + 1).map(opt => (
          <option
            key={opt}
            value={opt}
          >
            {opt}
          </option>
        ))}
      </select>
    </div>
  </div>);
}

export default DatePicker;
