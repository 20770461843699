import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DocumentPlusIcon, DocumentIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router'
import { uploadLicenseAttachment } from 'api/core';

const VALID_FILE_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "jpg",
  "png",
];

const AttachmentModal = ({ show, setShow, fileProp, setLicense }) => {
  const { id } = useParams();
  const [file, setFile] = useState(fileProp);
  const [error, setError] = useState();
  const cancelButtonRef = useRef(null);
  let inputFile = useRef(null);

  const fileHandler = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    const extension = e.target.files[0].name.split(".").at(-1);
    if (e.target.files[0]) {
      if (!VALID_FILE_EXTENSIONS.includes(extension)) {
        setError("File extension not supported");
      } else {
        setError(null);
      }
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newData = new FormData();
    newData.append("mechanical_license", id);
    newData.append("file", file);
    uploadLicenseAttachment(id, newData)
      .then((res) => {
        setLicense((prevState) => ({
          ...prevState,
          attachments: [...prevState.attachments, res.data],
        }));
        setShow(false);
      })
      .catch((res) => {
        // TODO: EDIT ERROR LOGGING
        console.log(res);
      });
  };

  useEffect(() => {
    !show && setFile(null);
  }, [show]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setShow}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div> */}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 flex justify-center"
                  >
                    <input
                      type="file"
                      ref={(input) => (inputFile = input)}
                      style={{ display: "none" }}
                      onChange={fileHandler}
                    />

                    {!file && <div className="flex justify-between items-center cursor-pointer hover:text-indigo-700" onClick={e=>{
                      e.preventDefault()
                      inputFile.click()
                    }}>
                      <DocumentPlusIcon className="mr-2 flex-shrink-0 h-6 w-6 " aria-hidden="true" />
                      Select file
                    </div>}
                    {file && <div className={`flex justify-between  cursor-pointer text-${error ? "red" : "indigo"}-600 hover:text-${error ? "red" : "indigo"}-700`} onClick={e=>{
                      e.preventDefault()
                      inputFile.click()
                    }}>
                      <DocumentIcon className={`mr-2 flex-shrink-0 h-6 w-6`} aria-hidden="true" />
                      {file.name}
                    </div>}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Valid file extensions are{" "}
                      {VALID_FILE_EXTENSIONS.slice(0, -1).map((ext, idx) => (
                        <span key={idx}>.{ext}, </span>
                      ))}{" "}
                      and .
                      {VALID_FILE_EXTENSIONS[VALID_FILE_EXTENSIONS.length - 1]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-${
                    file && !error ? "indigo" : "gray"
                  }-600 text-base font-medium text-white hover:bg-${
                    file && !error ? "indigo-700" : "gray-600"
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm`}
                  onClick={onSubmit}
                  disabled={file && !error ? false : true}
                >
                  Upload
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={onCancel}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AttachmentModal;
