import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const SelectField = ({ onChange, key, seterror, ...props }) => {
  const [value, setValue] = useState()
  const [defaultValue, setDefaultValue] = useState(props.defaultValue)
  const handleChange = (selectedOption) => {
    onChange(selectedOption)
    setValue(selectedOption)
  }

  useEffect(() => {
    if (props.options && props.value) {
      if (props.isMulti) {
        if (typeof props.value[0] === 'object' && props.value[0] !== null) {
          setValue(props.value)
        } else {
          setValue(props.value.forEach(valItem => props.options.find(item => item.value === valItem)))
        }
      } else {
        if (typeof props.value === 'object' && props.value !== null) {
          setValue(props.value)
        } else {
          setValue(props.options.find(item => item.value === props.value))
        }
      }
    } else {
      setValue(props.value)
    }

    if (props.defaultValue !== defaultValue) {
      setDefaultValue(props.defaultValue)
      setValue(props.defaultValue)
    }
  }, [props.value, props.defaultValue])

  return (
    <Select
      {...props}
      key={key}
      defaultValue={defaultValue}
      menuPortalTarget={document.body} 
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      onChange={handleChange}
      value={value}
    />
  )
}

export default SelectField
