import React from "react";
import { useFormContext } from "react-hook-form";

import FormLabel from "../../../../components/Form/FormLabel";
import FormError from "../../../../components/Form/FormError";
import CountrySelect from "../../../../components/Form/CountrySelect";
import { classNames } from "../../../../components/utils";
import AddressStateInput from "components/Form/AddressStateInput";

const LicenseeForm = ({ saveForLater, baseInputStyle }) => {
  const methods = useFormContext();
  const { register, watch } = methods;

  const country = watch('company.country');

  return (
    <>
      <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
        <div>
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            Licensee Information
          </h3>
        </div>
        <div className="mt-5 grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-6">
            <FormLabel label="Contact Name" name="licensee.name" required />
            <input
              type="text"
              name="licensee.name"
              className={baseInputStyle}
              {...register("licensee.name", { required: !saveForLater })}
            />
            <FormError name="licensee.name" />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <FormLabel label="Contact Email" name="licensee.email" required />
            <input
              type="email"
              name="licensee.email"
              className={baseInputStyle}
              {...register("licensee.email", { required: !saveForLater })}
            />
            <FormError name="licensee.email" />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <FormLabel label="Contact Phone" name="licensee.phone_number" />
            <input
              type="text"
              name="licensee.phone_number"
              className={baseInputStyle}
              {...register("licensee.phone_number")}
            />
            <FormError name="licensee.phone_number" />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <FormLabel
              label="How is the contact affiliated with the master owner"
              name="licensee.affiliation_with_owner"
              required
            />
            <input
              type="text"
              name="licensee.affiliation_with_owner"
              className={baseInputStyle}
              {...register("licensee.affiliation_with_owner", {
                required: !saveForLater,
              })}
            />
            <FormError name="licensee.affiliation_with_owner" />
          </div>

          <div className="col-span-6">
            <FormLabel
              label="Company Name for License"
              name="company.name"
              required
            />
            <input
              type="text"
              name="company.name"
              className={baseInputStyle}
              {...register("company.name", { required: !saveForLater })}
            />
            <FormError name="company.name" />
          </div>

          <div className="col-span-6 sm:col-span-6">
            <FormLabel
              label="Company Address"
              name="company.address"
              required
            />
            <input
              type="text"
              name="company.address"
              className={baseInputStyle}
              {...register("company.address", { required: !saveForLater })}
            />
            <FormError name="company.address" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel label="Company City" name="company.city" required />
            <input
              type="text"
              name="company.city"
              className={baseInputStyle}
              {...register("company.city", { required: !saveForLater })}
            />
            <FormError name="company.city" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel label="Company State" name="company.state" required={country === 'US'} />
            <AddressStateInput saveForLater={saveForLater} baseInputStyle={baseInputStyle} isUs={country === 'US'} />
            <FormError name="company.state" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel
              label="Company Zip"
              name="company.postal_code"
              required
            />
            <input
              type="text"
              name="company.postal_code"
              className={baseInputStyle}
              {...register("company.postal_code", { required: !saveForLater })}
            />
            <FormError name="company.postal_code" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel label="Company Country" name="company.country" />
            <CountrySelect name="company.country" methods={methods} required />
            <FormError name="company.country" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel
              label="Company Phone"
              name="company.phone_number"
              required
            />
            <input
              type="text"
              name="company.phone_number"
              className={baseInputStyle}
              {...register("company.phone_number", { required: !saveForLater })}
            />
            <FormError name="company.phone_number" />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <FormLabel
              label="License Signatory"
              name="licensee.license_signatory"
              required
            />
            <input
              type="text"
              name="licensee.license_signatory"
              className={baseInputStyle}
              {...register("licensee.license_signatory", { required: !saveForLater })}
            />
            <FormError name="licensee.license_signatory" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseeForm;
