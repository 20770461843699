import PrivateAPI from './private'
import PublicAPI from './public'

export const getDashboardStats = () => {
  return PrivateAPI.get('/api/stats/');
};

export const searchExpData = (assetType, queryParams) => {
  return PrivateAPI.post('/api/expdata-search/', {
    'asset_type': assetType,
    'query_params': queryParams
  });
};

export const getComposition = (code) => {
  return PrivateAPI.post('/api/compositions/', {
    'composition_code': code
  });
};

export const getRecording = (code) => {
  return PrivateAPI.post('/api/recordings/', {
    'recording_code': code
  });
};

export const saveMechanicalLicense = (data) => {
  return PrivateAPI.post('/api/licenses/mechanical/', data);
};

export const getMechanicalLicenses = (type, q) => {
  return PrivateAPI.get('/api/licenses/mechanical/', {
    'type': type,
    'q': q
  });
};

export const getMechanicalLicense = (pk) => {
  return PrivateAPI.get('/api/licenses/mechanical/' +  pk + '/');
};

export const editMechanicalLicense = (pk, data) => {
  return PrivateAPI.put('/api/licenses/mechanical/' +  pk + '/', data);
};

export const togglePaymentMechanicalLicense = (pk) => {
  return PrivateAPI.get('/api/licenses/mechanical/' +  pk + '/payment/');
};

export const deleteMechanicalLicense = (pk) => {
  return PrivateAPI.delete('/api/licenses/mechanical/' +  pk + '/');
};

export const saveSynchronizationLicense = (data) => {
  return PrivateAPI.post('/api/licenses/synchronization/', data);
};

export const getSynchronizationLicenses = (type, q) => {
  return PrivateAPI.get('/api/licenses/synchronization/', {
    'type': type,
    'q': q
  });
};

export const getSynchronizationLicense = (pk) => {
  return PrivateAPI.get('/api/licenses/synchronization/' +  pk + '/');
};

export const editSynchronizationLicense = (pk, data) => {
  return PrivateAPI.put('/api/licenses/synchronization/' +  pk + '/', data);
};

export const togglePaymentSynchronizationLicense = (pk) => {
  return PrivateAPI.get('/api/licenses/synchronization/' +  pk + '/payment/');
};

export const deleteSynchronizationLicense = (pk) => {
  return PrivateAPI.delete('/api/licenses/synchronization/' +  pk + '/');
};

export const uploadLicenseAttachment = (pk, data) => {
  return PrivateAPI.post(`/api/licenses/${pk}/attachment/`, data);
}

export const getLicenseAttachment = (pk) => {
  return PrivateAPI.get(`/api/license-attachment/${pk}/`)
}

export const downloadLicenseAttachment = (pk) => {
  return PrivateAPI.get(`/api/license-attachment/${pk}/download`, undefined, 'blob')
}

export const deleteLicenseAttachment = (pk) => {
  return PrivateAPI.delete(`/api/license-attachment/${pk}/`)
}
