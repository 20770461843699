import React from 'react';

const RecordingInformation = ({ recordings }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Recording Information
        </h2>
      </div>
        {recordings.map((recording, index) => (
          <div key={index} className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl key={index} className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-xs font-medium text-gray-500">Controlled?</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.controlled ? 'Yes' : 'No'}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Recording Title</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.title}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Recording Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.exp_recording_code}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Controlled Artists</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.controlled_artists}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Uncontrolled Artists</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.uncontrolled_artists}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Client Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.client_name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Client Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.client_code}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Controlled %</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.controlled_percentage}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Territory</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.territory}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Credits</dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{recording?.credits}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900">{recording?.status}</dd>
              </div>
            </dl>
          </div>
        ))}
    </div>
  </section>);
}

export default RecordingInformation;
