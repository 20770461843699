import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormLabel from '../../../../components/Form/FormLabel';
import FormError from '../../../../components/Form/FormError';

const Quote = ({ saveForLater, baseInputStyle }) => {
  const methods = useFormContext();
  const { register } = methods;

  return (<div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
    <div>
      <h3 className="text-xl font-medium leading-6 text-gray-900">Quote</h3>
    </div>
    <div className="mt-5">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <FormLabel label='Clearance quote/License' name='project.clearance_quote' required />
          <textarea
            id={`project.clearance_quote`}
            name={`project.clearance_quote`}
            rows={3}
            className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
            {...register(`project.clearance_quote`, { required: !saveForLater })}
          />
          <FormError name='project.clearance_quote' />
        </div>
      </div>
    </div>
  </div>);
}

export default Quote;
