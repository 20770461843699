export const buildFormData = (data) => {
  const newData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    if (key === "attachments") {
      value.forEach((attachment, i) => {
        if (attachment.id) {
          newData.append("attachment_" + i, JSON.stringify(attachment))
        } else {
          newData.append("attachment_" + i, attachment)
        }
      })
    } else {
      newData.append(key, JSON.stringify(value))
    }
  })
  return newData
}

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}
