import React, { useEffect } from "react"
import { useFormContext, useFieldArray } from "react-hook-form"

import { SONG_DEFAULT_VALUES } from "../../../../components/const"
import FormLabel from "../../../../components/Form/FormLabel"
import FormError from "../../../../components/Form/FormError"
import RenderWriters from "./RenderWriters"

const baseInputStyle =
  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"

const SongForm = ({ saveForLater, songIndex, setSongIndex, songWriters, setSongWriters }) => {
  const methods = useFormContext()
  const { register, control } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "songs",
    keyName: "uuid",
  })

  const removeSong = (index) => {
    remove(index)
    let writerList = Object.assign({}, songWriters)
    delete writerList[index]
    setSongWriters(writerList)
    setSongIndex(songIndex - 1)
  }

  const addNewSong = () => {
    append(SONG_DEFAULT_VALUES)
    setSongIndex(songIndex + 1)
  }

  return (
    <>
      <div className="pt-6">
        <div>
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            Song Information
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            This section pulls information from{" "}
            <a
              href="https://data.exploration.io"
              className="text-blue-500"
              target="_blank"
              rel="noreferrer"
            >
              Exploration Data
            </a>
          </p>
        </div>
        {fields.map((song, index) => (
          <div key={song.uuid}>
            <div key={index} className="mt-4 grid grid-cols-9 gap-6">
              <div className="flex flex-row justify-between col-span-9 -mb-2">
                <div className="col-span-6">
                  Song #{index + 1}
                  <span
                    className="ml-2 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-red-indigo cursor-pointer hover:bg-indigo-100"
                    onClick={() => setSongIndex(index)}
                  >
                    Pull Info
                  </span>
                </div>

                {fields.length > 1 && (
                  <button
                    className="btn btn-link btn-remove text-sm text-gray-500"
                    type="button"
                    onClick={() => removeSong(index)}
                  >
                    Remove
                  </button>
                )}
              </div>

              <div className="col-span-9">
                <FormLabel
                  label="Song Title"
                  name={`songs[${index}].title`}
                  required
                />
                <input
                  type="hidden"
                  name={`songs[${index}].id`}
                  id={`songs[${index}].id`}
                  {...register(`songs[${index}].id`)}
                />
                <input
                  type="text"
                  name={`songs[${index}].title`}
                  id={`songs[${index}].title`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].title`, { required: !saveForLater })}
                />
                <FormError name={`songs[${index}].title`} />
              </div>

              <div className="col-span-3">
                <FormLabel
                  label="Composition Code"
                  name={`songs[${index}].exp_composition_code`}
                />
                <input
                  type="text"
                  name={`songs[${index}].exp_composition_code`}
                  id={`songs[${index}].exp_composition_code`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].exp_composition_code`)}
                />
                <FormError name={`songs[${index}].exp_composition_code`} />
              </div>

              <div className="col-span-6">
                <FormLabel
                  label="Custom ID"
                  name={`songs[${index}].custom_id`}
                />
                <input
                  type="text"
                  name={`songs[${index}].custom_id`}
                  id={`songs[${index}].custom_id`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].custom_id`)}
                />
                <FormError name={`songs[${index}].custom_id`} />
              </div>

              <div className="col-span-12">
                <RenderWriters saveForLater={saveForLater} songIndex={index} songWriters={songWriters} />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <FormLabel
                  label="Uncontrolled Publishers"
                  name={`songs[${index}].uncontrolled_publishers`}
                />
                <input
                  type="text"
                  name={`songs[${index}].uncontrolled_publishers`}
                  id={`songs[${index}].uncontrolled_publishers`}
                  {...register(`songs[${index}].uncontrolled_publishers`)}
                  className={baseInputStyle}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <FormLabel
                  label="Uncontrolled Writers"
                  name={`songs[${index}].uncontrolled_writers`}
                />
                <input
                  type="text"
                  name={`songs[${index}].uncontrolled_writers`}
                  id={`songs[${index}].uncontrolled_writers`}
                  {...register(`songs[${index}].uncontrolled_writers`)}
                  className={baseInputStyle}
                />
              </div>

              <div className="col-span-9">
                <FormLabel label="Credits" name={`songs[${index}].credits`} />
                <textarea
                  id={`songs[${index}].credits`}
                  name={`songs[${index}].credits`}
                  rows={3}
                  className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300"
                  {...register(`songs[${index}].credits`)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-end mt-6">
          <button
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
            onClick={() => addNewSong()}
          >
            Add Song
          </button>
        </div>
      </div>
    </>
  )
}

export default SongForm
