import React, { Fragment, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import {
  HomeIcon,
  DocumentTextIcon,
  FilmIcon,
  PlusCircleIcon,
  DuplicateIcon,
  CashIcon,
  XMarkIcon,
  ArchiveIcon,
  UsersIcon,
  PresentationChartBarIcon,
  DocumentSearchIcon,
  UploadIcon,
  DownloadIcon
} from '@heroicons/react/24/outline'

import ProfileImage from 'components/ProfileImage'
import { AuthContext } from 'stores/auth'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Mechanical', href: '/licenses/mechanical', icon: DocumentTextIcon },
  { name: 'Synchronization', href: '/licenses/synchronization', icon: FilmIcon }
  // { name: 'Create Assets', href: '/create-assets', icon: PlusCircleIcon },
  // { name: 'Import Data', href: '/upload', icon: UploadIcon },
  // { name: 'Export Data', href: '/download', icon: DownloadIcon },
  // { name: 'Duplicates', href: '/duplicates', icon: DuplicateIcon },
  // { name: 'Conflicts', href: '/conflicts', icon: CashIcon },
  // { name: 'Claiming', href: '/claiming', icon: ArchiveIcon },
  // { name: 'Users', href: '/users', icon: UsersIcon },
  // { name: 'Billboard Charts', href: '/billboard-charts', icon: PresentationChartBarIcon },
  // { name: 'MLC Search', href: '/mlc-search', icon: DocumentSearchIcon }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Menu = ({ sidebarOpen, setSidebarOpen }) => {
  const authStore = useContext(AuthContext)
  const location = useLocation()

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 flex z-40 lg:hidden'
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
                <div className='flex-shrink-0 flex items-center px-4'>
                  <img
                    className='h-15 w-auto'
                    src='/images/new-exploration-logo.png'
                    alt='ExplorationData'
                  />
                </div>
                <nav aria-label='Sidebar' className='mt-5'>
                  <div className='px-2 space-y-1'>
                    {navigation.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-indigo-600 text-white'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-white'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </nav>
              </div>
              <div className='flex-shrink-0 flex border-t border-gray-200 p-4'>
                <a href='#' className='flex-shrink-0 group block'>
                  <div className='flex items-center'>
                    <div>
                      <ProfileImage
                        email={authStore?.user?.email}
                        className='inline-block h-10 w-10 rounded-full'
                      />
                    </div>
                    <div className='ml-3'>
                      <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                        {authStore?.user?.first_name +
                          ' ' +
                          authStore?.user?.last_name}
                      </p>
                      <p className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                        {authStore?.user?.email}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:flex lg:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-col h-0 flex-1 border-r border-gray-200 bg-indigo-500'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-4'>
                <img
                  className='h-4 w-auto'
                  src='/assets/images/Logo.png'
                  alt='ExplorationData'
                />
                <div className='text-3xl text-white font-bold ml-2'>
                  Licensing
                </div>
              </div>
              <nav className='mt-5 flex-1' aria-label='Sidebar'>
                <div className='px-2 space-y-1'>
                  {navigation.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        (
                          item.name === 'Dashboard'
                            ? location.pathname === item.href
                            : location.pathname.includes(item.href)
                        )
                          ? 'bg-indigo-700 text-white font-bold'
                          : 'text-white hover:bg-white hover:text-black',
                        'group flex items-center px-2 py-2 text-sm rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          (
                            item.name === 'Dashboard'
                              ? location.pathname === item.href
                              : location.pathname.includes(item.href)
                          )
                            ? 'text-white'
                            : 'group-hover:text-black',
                          'mr-3 h-4 w-4'
                        )}
                        aria-hidden='true'
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </nav>
            </div>
            <div className='flex-shrink-0 flex border-t border-indigo-400 p-4'>
              <a href='#' className='flex-shrink-0 w-full group block'>
                <div className='flex items-center'>
                  <div className='flex-shrink-0'>
                    <ProfileImage
                      email={authStore?.user?.email}
                      className='inline-block h-9 w-9 rounded-full'
                    />
                  </div>
                  <div className='ml-3 truncate'>
                    <p className='text-sm font-medium text-white truncate'>
                      {authStore?.user?.first_name +
                        ' ' +
                        authStore?.user?.last_name}
                    </p>
                    <p className='text-xs font-medium text-white truncate'>
                      {authStore?.user?.email}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
