import React from 'react';

const FormLabel = ({ name, label, required }) => {
  return (<div className="flex">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    {required && (<span className="text-sm text-red-400" id={name}>
      &nbsp;*
    </span>)}
  </div>);
}

export default FormLabel;
