import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

import { getMechanicalLicense } from 'api/core';
import Header from './components/Header';
import Footer from './components/Footer';
import LicenseInformation from './components/LicenseInformation';
import SongInformation from './components/SongInformation';
import ReleaseInformation from './components/ReleaseInformation';
import LicenseeInformation from './components/LicenseeInformation';
import LicenseTerms from './components/LicenseTerms';
import Notes from './components/Notes';
import Files from './components/Files';

const Detail = () => {
  const componentIsMounted = useRef(true);
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [license, setLicense] = useState();

  useEffect(() => {
    if (!componentIsMounted.current) return;
    setLoading(true);
    getMechanicalLicense(id).then(res => {
      setLicense(res.data);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (<>
    {loading && (<>
      <ArrowPathIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      <div className="flex items-start space-x-5">
        <div className="pt-1.5">
          <h1 className="text-2xl font-bold text-gray-900">Loading mechanical license...</h1>
        </div>
      </div>
    </>)}

    {!loading && !license && (<>
      <div className="flex items-start space-x-5">
        <div className="pt-1.5">
          <h1 className="text-2xl font-bold text-gray-900">Error in loading mechanical license.</h1>
        </div>
      </div>
    </>)}

    {!loading && license && (<>
      <Header license={license} setLicense={setLicense}/>
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

        <div className="space-y-6 lg:col-start-1 lg:col-span-2 p-wrap">
          <LicenseInformation license={license} />
          <SongInformation songs={license?.songs} />
          <ReleaseInformation release={license?.release} />
          <LicenseeInformation licensee={license?.licensee} />
          <LicenseTerms license={license} />
          <Notes notes={license?.notes} />
          <Footer license={license} />
        </div>

        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
          <Files license={license} setLicense={setLicense} />
        </section>
      </div>
    </>)}
  </>);
}

export default Detail;
