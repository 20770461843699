import React, { useState } from 'react'
import {
  Bars3Icon
} from '@heroicons/react/24/outline'

import Header from "./components/Header"
import Menu from "./components/Menu"

const BaseContainer = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (<>
    <div className="h-screen flex overflow-hidden bg-white">
      <Menu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
            <a href='/'>
              <img
                className="h-15 w-auto"
                src="/images/new-exploration-logo.png"
                alt="ExplorationData"
              />
            </a>
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto bg-gray-50">
          {/* Start main area*/}
          <div className="absolute inset-0">
            <div className="flex flex-col h-screen h-full">
              <div className="min-w-full">
                <Header />
              </div>
              <div className="min-w-full">
                {children}
              </div>
            </div>
          </div>
          {/* End main area */}
        </main>
      </div>
    </div>
  </>);
}

export default BaseContainer;
