import React, { useEffect, useState } from 'react';
import { getDashboardStats } from 'api/core';

export default function LicenseStats() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([
    { name: 'Completed', mech: '0', sync: '0' },
    { name: 'In Progress', mech: '0', sync: '0' },
    { name: 'Archived', mech: '0', sync: '0' }
  ]);

  useEffect(() => {
    setLoading(true);
    getDashboardStats().then(res => {
      setStats(res.data);
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">License Stats</h2>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.mech}
                <span className="ml-2 text-sm font-medium text-gray-500">Mech</span>
              </div>
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.sync}
                <span className="ml-2 text-sm font-medium text-gray-500">Sync</span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
