import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CommonProvider from 'stores/common';

import BaseContainer from 'containers/BaseContainer';
import BlankRoute from './BlankRoute';

import Dashboard from './Dashboard';
import EmailConfirmation from './Auth/EmailConfirmation';
import ListMechanical from './Mechanical/List';
import DetailMechanical from './Mechanical/Detail';
import EditMechanical from './Mechanical/Edit';
import CreateMechanical from './Mechanical/Create';
import ListSynchronization from './Synchronization/List';
import DetailSynchronization from './Synchronization/Detail';
import EditSynchronization from './Synchronization/Edit';
import CreateSynchronization from './Synchronization/Create';

const ProtectedRoutes = () => (
  <CommonProvider>
    <BaseContainer>
      <div className="container__wrap p-6">
        <Switch>
          {/* <Route path="/account/verify" component={EmailConfirmation} /> */}
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/licenses/mechanical" component={ListMechanical} />
          <Route exact path="/licenses/mechanical/add" component={CreateMechanical} />
          <Route exact path="/licenses/mechanical/:id" component={DetailMechanical} />
          <Route exact path="/licenses/mechanical/:id/edit" component={EditMechanical} />

          <Route exact path="/licenses/synchronization" component={ListSynchronization} />
          <Route exact path="/licenses/synchronization/add" component={CreateSynchronization} />
          <Route exact path="/licenses/synchronization/:id" component={DetailSynchronization} />
          <Route exact path="/licenses/synchronization/:id/edit" component={EditSynchronization} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </BaseContainer>
  </CommonProvider>
)

export default ProtectedRoutes
