import { timeToMinutesAndSeconds } from "components/utils"

const formatSongs = (data) => {
  let songs = []

  for (const song of data) {
    songs.push({
      id: song?.id,
      title: song?.title,
      exp_composition_code: song?.exp_composition_code,
      custom_id: song?.custom_id,
      controlled_writer_list: song?.controlled_writer_list,
      uncontrolled_writers: song?.uncontrolled_writers,
      uncontrolled_publishers: song?.uncontrolled_publishers,
      credits: song?.credits,
    })
  }
  return songs
}

export const formatFields = (data) => {
  const { minutes: recordingLengthMinutes, seconds: recordingLengthSeconds } =
    timeToMinutesAndSeconds(data.release.recording_length)

  return {
    id: data.id,
    license_id: data.license_id,
    licensing_type: data.licensing_type,
    songs: formatSongs(data.songs),
    indefinite_term_length: data.indefinite_term_length,
    licensed_territory: data.licensed_territory,
    rate: data.rate,
    is_current_statutory_rate: data.is_current_statutory_rate,
    units: data.units,
    all_units: data.all_units,
    advance_on_units: data.advance_on_units,
    payment_terms: data.payment_terms,
    notes: {
      id: data.notes?.id,
      content: data.notes?.content,
    },
    release: {
      id: data.release.id,
      title: data.release.title,
      recording_artist: data.release.recording_artist,
      project_code: data.release.project_code,
      record_label: data.release.record_label,
      distributor: data.release.distributor,
      isrc: data.release.isrc,
      upc: data.release.upc,
      recording_length: data.release.recording_length,
      recording_length_minutes: recordingLengthMinutes,
      recording_length_seconds: recordingLengthSeconds,
      release_date: data.release.release_date,
      release_type: data.release.release_type,
      release_territory: data.release.release_territory,
      formats: data.release.formats,
      format: {
        digital_download: data.release.formats.includes("digital_download"),
        streaming: data.release.formats.includes("streaming"),
        vinyl: data.release.formats.includes("vinyl"),
        cd: data.release.formats.includes("cd"),
        cassette: data.release.formats.includes("cassette"),
        "8_track": data.release.formats.includes("8_track"),
        other: data.release.formats.includes("other"),
      },
      other_format: data.release.other_format,
      platforms: {
        streaming: data.release.streaming,
        physical: data.release.physical,
        digital_download: data.release.digital_download,
        other: data.release.other_platform ? true : false,
      },
      other_platform: data.release.other_platform,
      manufacture_date: data.release.manufacture_date,
      physical_units: data.release.physical_units,
    },
    licensee: {
      id: data.licensee.id,
      name: data.licensee.name,
      email: data.licensee.email,
      phone_number: data.licensee.phone_number,
      affiliation_with_owner: data.licensee.affiliation_with_owner,
      license_signatory: data.licensee.license_signatory,
    },
    company: {
      id: data.licensee.company.id,
      name: data.licensee.company.name,
      address: data.licensee.company.address,
      city: data.licensee.company.city,
      state: data.licensee.company.state,
      postal_code: data.licensee.company.postal_code,
      phone_number: data.licensee.company.phone_number,
      country: data.licensee.company.country,
    },
    license_date: data.license_date,
    term_length: data.term_length,
    attachments: data.attachments,
  }
}
