import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Element } from "react-scroll";

import { classNames } from "../../../../components/utils";
import FormLabel from "../../../../components/Form/FormLabel";
import FormError from "../../../../components/Form/FormError";
import DatePicker from "../../../../components/Form/DatePicker";
import SongForm from "./SongForm";
import LicenseeForm from "./LicenseeForm";
import ReleaseForm from "./ReleaseForm";
import Attachments from "./Attachments";

const baseInputStyle =
  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md";
const readOnlyInputStyle =
  "mt-1 block w-full bg-gray-200 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-0 focus:border-gray-300";

const Form = ({ saving, saveForLater, songIndex, setSongIndex, songWriters, setSongWriters }) => {
  const methods = useFormContext();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { isDirty, isValid },
  } = methods;
  const [showTermLengthDatePicker, setShowTermLengthDatePicker] = useState(
    !getValues("indefinite_term_length")
  );
  const [showUnitsField, setShowUnitsField] = useState(!getValues("all_units"));
  const [isCurrentStatutoryRate, setIsCurrentStatutoryRate] = useState(getValues("is_current_statutory_rate") || false);
  const [paymentReceived, setPaymentReceived] = useState(getValues("payment_received") || false); 

  const handleTermLengthCheckbox = (name) => {
    const val = getValues(name);
    setValue(name, !val);
    setShowTermLengthDatePicker(val);
  };

  const handlePhysicalUnitsCheckbox = (name) => {
    const unitsVal = getValues("units");
    if (!unitsVal) {
      setValue("units", 0);
    }
    setValue(name, !unitsVal);
    setShowUnitsField(!showUnitsField);
  };

  const handleCurrentRate = () => {
    setValue('is_current_statutory_rate', !isCurrentStatutoryRate)
    setValue('rate', 0)
    setIsCurrentStatutoryRate(!isCurrentStatutoryRate)
  };

  const handlePaymentReceived = () => {
    setValue('payment_received', !paymentReceived)
    setPaymentReceived(!paymentReceived)
  };

  return (
    <>
      <div className="mt-5 space-y-6">
        <Element name="song-information">
          <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-6">
              <div className="grid grid-cols-9 gap-6">
                <div className="grid col-span-6 grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <FormLabel label="License ID" name="license_id" required />
                    <div className="mt-1 rounded-md shadow-sm flex">
                      <span className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                        MECH-
                      </span>
                      <input
                        type="text"
                        name="license_id"
                        id="license_id"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="####"
                        {...register("license_id", { required: !saveForLater })}
                      />
                    </div>
                    <FormError name="license_id" />
                  </div>
                </div>
                <div className="grid col-span-3 grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <FormLabel
                      label="License or NOI"
                      name="licensing_type"
                      required
                    />
                    <div className="flex rounded-md shadow-sm">
                      <select
                        id="licensing_type"
                        name="licensing_type"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("licensing_type", { required: !saveForLater })}
                      >
                        <option value="license">License</option>
                        <option value="noi">NOI</option>
                      </select>
                    </div>
                    <FormError name="licensing_type" />
                  </div>
                </div>
              </div>

              <SongForm saveForLater={saveForLater} songIndex={songIndex} setSongIndex={setSongIndex} songWriters={songWriters} setSongWriters={setSongWriters} />
            </div>
          </div>
        </Element>

        <Element name="release-information">
          <ReleaseForm saveForLater={saveForLater} register={register} baseInputStyle={baseInputStyle} />
        </Element>

        <Element name="licensee-information">
          <LicenseeForm saveForLater={saveForLater} register={register} baseInputStyle={baseInputStyle} />
        </Element>

        <Element name="license-terms">
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  License Terms
                </h3>
              </div>
              <div className="mt-5">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <FormLabel
                      label="License Start Date"
                      name="license_date"
                      required
                    />
                    <DatePicker
                      name="license_date"
                      methods={methods}
                      future={5}
                      required
                    />
                    <FormError name="license_date" />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <FormLabel
                      label="License End Date"
                      name="term_length"
                      required
                    />
                    {showTermLengthDatePicker && (
                      <DatePicker
                        name="term_length"
                        methods={methods}
                        future={5}
                        required
                      />
                    )}
                    <div className="flex items-start mt-2">
                      <div className="h-5 flex items-center">
                        <input
                          id={"indefinite_term_length"}
                          name={"indefinite_term_length"}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          {...register("indefinite_term_length")}
                          onChange={() =>
                            handleTermLengthCheckbox("indefinite_term_length")
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor={"indefinite_term_length"}
                          className="font-normal text-gray-700"
                        >
                          Indefinite
                        </label>
                      </div>
                    </div>
                    <FormError name="term_length" />
                  </div>

                  <div className="col-span-6">
                    <FormLabel
                      label="Licensed Territory"
                      name="licensed_territory"
                      required
                    />
                    <input
                      type="text"
                      name="licensed_territory"
                      id="licensed_territory"
                      className={baseInputStyle}
                      {...register("licensed_territory", { required: !saveForLater })}
                    />
                    <FormError name="licensed_territory" />
                  </div>

                  <div className="col-span-1">
                    <FormLabel label="Rate ($)" name="rate" required />
                    {!isCurrentStatutoryRate && (<input
                        type="number"
                        min="0"
                        step="any"
                        name="rate"
                        id="rate"
                        className={baseInputStyle}
                        {...register("rate", { required: !saveForLater })}
                      />)
                    }
                    <div className="flex items-start mt-2">
                      <div className="h-5 flex items-center">
                        <input
                          id={"current_rate"}
                          name={"current_rate"}
                          type="checkbox"
                          checked={isCurrentStatutoryRate}
                          value={isCurrentStatutoryRate}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          onChange={() =>
                            handleCurrentRate()
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor={"current_rate"}
                          className="font-normal text-gray-700"
                        >
                          Current Statutory Rate
                        </label>
                      </div>
                    </div>
                    <FormError name="rate" />
                  </div>

                  <div className="col-span-1">
                    <FormLabel label="Units" name="units" required />
                    {showUnitsField && (
                      <input
                        type="number"
                        name="units"
                        id="units"
                        className={baseInputStyle}
                        {...register("units", { required: !saveForLater })}
                      />
                    )}
                    <div className="flex items-start mt-2">
                      <div className="h-5 flex items-center">
                        <input
                          id={"all_units"}
                          name={"all_units"}
                          checked={!showUnitsField}
                          value={!showUnitsField}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          {...register("all_units")}
                          onChange={() =>
                            handlePhysicalUnitsCheckbox("all_units")
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor={"all_units"}
                          className="font-normal text-gray-700"
                        >
                          All
                        </label>
                      </div>
                    </div>
                    <FormError name="units" />
                  </div>

                  <div className="col-span-1">
                    <FormLabel
                      label="Adv. on Units ($)"
                      name="advance_on_units"
                    />
                    <input
                      type="number"
                      min="0"
                      step="any"
                      name="advance_on_units"
                      id="advance_on_units"
                      className={baseInputStyle}
                      {...register("advance_on_units")}
                    />
                    <FormError name="advance_on_units" />
                  </div>

                  <div className="col-span-3">
                    <FormLabel
                      label="Payment Terms"
                      name="payment_terms"
                      required
                    />
                    <input
                      type="text"
                      name="payment_terms"
                      id="payment_terms"
                      className={baseInputStyle}
                      {...register("payment_terms", { required: !saveForLater })}
                    />
                    <FormError name="payment_terms" />
                    <div className="flex items-start mt-2">
                      <div className="h-5 flex items-center">
                        <input
                          id={"payment_received"}
                          name={"payment_received"}
                          checked={paymentReceived}
                          value={paymentReceived}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          onChange={() =>
                            handlePaymentReceived()
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor={"payment_received"}
                          className="font-normal text-gray-700"
                        >
                          Payment Received?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>

        <Element>
          <Attachments />
        </Element>

        <Element name="notes">
          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
            <div>
              <h3 className="text-xl font-medium leading-6 text-gray-900">
                Notes/Payment Tracking
              </h3>
            </div>
            <div className="mt-3">
              <div>
                <textarea
                  id="notes.content"
                  name="notes.content"
                  rows={5}
                  className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  {...register("notes.content")}
                />
              </div>
            </div>
          </div>
        </Element>
      </div>
    </>
  );
};

export default Form;
