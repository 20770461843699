import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getMechanicalLicenses, deleteMechanicalLicense, togglePaymentMechanicalLicense } from 'api/core';

const MechanicalLicenses = ({ searchQ }) => {
  const [loading, setLoading] = useState(false);
  const [licenses, setLicenses] = useState();
  const [inProgressLoading, setInProgressLoading] = useState(false);
  const [inProgress, setInProgress] = useState();

  const getList = () => {
    setLoading(true);
    getMechanicalLicenses("completed", searchQ).then(res => {
      setLicenses(res.data?.data);
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
  }

  const getInProgress = () => {
    setInProgressLoading(true);
    getMechanicalLicenses("in_progress", searchQ).then(res => {
      setInProgress(res.data?.data);
      setInProgressLoading(false);
    }).catch((err) => {
      console.log(err)
      setInProgressLoading(false);
    });
  }

  useEffect(() => {
    getList();
    getInProgress();
  }, [searchQ]);

  const onDelete = (pk) => {
    deleteMechanicalLicense(pk).then(res => {
      getList();
      getInProgress();
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
  }

  const togglePayment = (pk) => {
    togglePaymentMechanicalLicense(pk)
    .then(res=>{
      getList();
    })
    .catch(res=>{
      // TODO: redo error logging
      console.log(res)
    })
  }

  return (<div>
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">Mechanical Licenses</h1>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <Link
          to={'/licenses/mechanical/add'}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create
        </Link>
      </div>
    </div>
    <div className="mt-5 flex flex-col bg-white">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    License ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Song
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Terms
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Licensee
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Territory
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && licenses?.map((license, idx) => (
                  <tr key={license.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <Link
                        to={'/licenses/mechanical/' + license.id + '/'}
                        className="text-md leading-6 font-medium text-gray-900"
                      >
                        MECH-{license.license_id}
                      </Link>
                      <div>
                        {license.licensing_type === 'license' && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          License
                        </span>)}
                        {license.licensing_type === 'noi' && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          NOI
                        </span>)}<br />
                        {license.payment_received
                          ? (<span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 cursor-pointer"
                              onClick={() => togglePayment(license.id)}>
                              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                              </svg>
                              Payment Received
                          </span>)
                          : (<span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 cursor-pointer"
                              onClick={() => togglePayment(license.id)}>
                              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                              </svg>
                              Payment Not Received
                          </span>)
                        }
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="text-md leading-6 font-medium text-gray-900">{license.songs[0]?.title}</span>
                      <p className="text-xs text-gray-500">{license.songs[0]?.exp_composition_code}</p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="text-md leading-6 font-medium text-gray-900">{license?.license_date}</span>
                      <p className="text-xs text-gray-500">
                        Rate:&nbsp;
                        {license?.is_current_statutory_rate
                          ? (<span>Current Statutory Rate</span>)
                          : (<span>{license?.rate}</span>)
                        }
                    </p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {license.licensee?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {license.licensed_territory}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <div className="grid grid-cols-3 gap-2">
                        <Link
                          to={'/licenses/mechanical/' + license.id + '/'}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View
                        </Link>
                        <Link
                          to={'/licenses/mechanical/' + license.id + '/edit'}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </Link>
                        <button
                          type="button"
                          onClick={() => {
                            if (window.confirm('Delete this mechanical license?')) {
                              onDelete(license.id)
                            }
                          }}
                          className="text-red-600 hover:text-red-900">
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {loading && (
                  <tr className='bg-white'>
                    <td colSpan={6}>
                      <div className="text-center px-6 py-6">
                        Loading...
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className="flex-1 min-w-0">
      <h2 className="mt-5 text-xl font-bold leading-7 text-gray-900 sm:truncate">In Progress</h2>
    </div>
    <div className="mt-5 flex flex-col bg-white">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    License ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Song
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Terms
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Licensee
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Territory
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!inProgressLoading && inProgress?.map((license, idx) => (
                  <tr key={license.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <Link
                        to={'/licenses/mechanical/' + license.id + '/'}
                        className="text-md leading-6 font-medium text-gray-900"
                      >
                        MECH-{license.license_id}
                      </Link>
                      <div>
                        {license.licensing_type === 'license' && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          License
                        </span>)}
                        {license.licensing_type === 'noi' && (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          NOI
                        </span>)}<br />
                        {license.payment_received
                          ? (<span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 cursor-pointer"
                              onClick={() => togglePayment(license.id)}>
                              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                              </svg>
                              Payment Received
                          </span>)
                          : (<span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 cursor-pointer"
                              onClick={() => togglePayment(license.id)}>
                              <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                              <circle cx={4} cy={4} r={3} />
                              </svg>
                              Payment Not Received
                          </span>)
                        }
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="text-md leading-6 font-medium text-gray-900">{license.songs[0]?.title}</span>
                      <p className="text-xs text-gray-500">{license.songs[0]?.exp_composition_code}</p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="text-md leading-6 font-medium text-gray-900">{license?.license_date}</span>
                      <p className="text-xs text-gray-500">
                        Rate:&nbsp;
                        {license?.is_current_statutory_rate
                          ? (<span>Current Statutory Rate</span>)
                          : (<span>{license?.rate}</span>)
                        }
                    </p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {license.licensee?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {license.licensed_territory}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <div className="grid grid-cols-3 gap-2">
                        <Link
                          to={'/licenses/mechanical/' + license.id + '/'}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View
                        </Link>
                        <Link
                          to={'/licenses/mechanical/' + license.id + '/edit'}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </Link>
                        <button
                          type="button"
                          onClick={() => {
                            if (window.confirm('Delete this mechanical license?')) {
                              onDelete(license.id)
                            }
                          }}
                          className="text-red-600 hover:text-red-900">
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {loading && (
                  <tr className='bg-white'>
                    <td colSpan={6}>
                      <div className="text-center px-6 py-6">
                        Loading...
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default MechanicalLicenses;
