import React, { useEffect, useState, useMemo } from 'react';
import countryList from 'react-select-country-list';
import _ from 'lodash';

import { classNames } from '../utils';

const Months = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
]

const CountrySelect = ({ name, methods, required }) => {
  const { register, formState, setValue, setError, clearErrors } = methods;
  const { errors } = formState;
  const fieldError = errors?.[name];

  const Countries = useMemo(() => countryList().getData(), [])

  return (<div
    className={classNames(
      fieldError
        ? 'border-red-300'
        : 'border-gray-300',
      'mt-1 border rounded-md'
    )}
  >
    <div className="w-full">
      <select
        id={name}
        name={name}
        className="col-span-5 block w-full pl-3 pr-10 py-2 text-base border-0 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-none rounded-tl-md rounded-bl-md border-r-0"
        {...register(name, { required: required })}
      >
        {Countries.map(opt => (
          <option
            key={opt.value}
            value={opt.value}
          >
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  </div>);
}

export default CountrySelect;
