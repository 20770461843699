import React from 'react';

const LicenseTerms = ({ license }) => {
  const lengthOfTerm = license?.indefinite_term_length ? 'Indefinite' : license?.term_length
  const physicalUnits = license?.all_physical_units ? 'All' : license?.physical_units

  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          License Terms
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">License Start Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{license?.license_date}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">License End Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{lengthOfTerm}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Licensed Territory</dt>
            <dd className="mt-1 text-sm text-gray-900">{license?.licensed_territory}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Fee</dt>
            <dd className="mt-1 text-sm text-gray-900">{license?.fee}</dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Payment Terms</dt>
            <dd className="mt-1 text-sm text-gray-900">{license?.payment_terms}</dd>
          </div>
        </dl>
      </div>
    </div>
  </section>);
}

export default LicenseTerms;
