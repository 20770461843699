import React from 'react';

const LicenseeInformation = ({ licensee }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Licensee Information
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.name}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Phone Number</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.phone_number}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Affiliation with Owner</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.affiliation_with_owner}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.name}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company Address</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.address}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company City</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.city}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company State</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.state}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company Country</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.country}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company Zip</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.postal_code}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Company Phone</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.company?.phone_number}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">License Signatory</dt>
            <dd className="mt-1 text-sm text-gray-900">{licensee?.license_signatory}</dd>
          </div>
        </dl>
      </div>
    </div>
  </section>);
}

export default LicenseeInformation;
