import React from 'react';
import { useHistory } from 'react-router';
import { deleteMechanicalLicense } from 'api/core';

const Footer = ({ license }) => {
  const history = useHistory()

  const onDelete = (pk) => {
    deleteMechanicalLicense(pk).then(res => {
      history.push('/licenses/mechanical/')
    }).catch((err) => {
      console.log(err)
    });
  }

  return (<>
      <div className="mt-6">
        <button
          type="button"
          onClick={() => {
            if (window.confirm('Delete this mechanical license?')) {
              onDelete(license.id)
            }
          }}
          className="inline-flex items-center justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-transparent hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-500"
        >
          Delete License
        </button>
      </div>
  </>);
}

export default Footer;
