import React from 'react';

const SongInformation = ({ songs }) => {
  return (<>
    {songs.map((song, index) => (
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              {songs.length > 1 
                ? <span>Song # {index + 1}</span> 
                : <span>Song Information</span>
              }
            </h2>
          </div>
          <div key={index} className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl key={index} className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Song Title</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.title}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Composition Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.exp_composition_code}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Custom ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.custom_id}</dd>
              </div>
              <h3 id="applicant-information-title" className="text-md leading-6 font-medium text-gray-900 col-span-2">
                Controlled Writers
              </h3>
              {song?.controlled_writer_list?.map((writer, index) => {
                return(<>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Controlled Writer {index+1}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.name}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Client Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.client_name}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Client Code</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.client_code}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Controlled %</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.controlled_percentage}</dd>
                  </div>
                  <div className="sm:col-span-2 mb-5">
                    <dt className="text-xs font-medium text-gray-500">Territory</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.territory}</dd>
                  </div>
                </>)
              })
              }
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Uncontrolled Publishers</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.uncontrolled_publishers}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Uncontrolled Writers</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.uncontrolled_writers}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Credits</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.credits}</dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
    ))}
  </>);
}

export default SongInformation;
