import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const InfoBar = ({ text, onClose, showClose }) => (
  <div className="mb-5 rounded-md bg-indigo-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-indigo-800">{text}</p>
      </div>
      {showClose && (<div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex bg-indigo-50 rounded-md p-1.5 text-indigo-500 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>)
      }
    </div>
  </div>
)

export default InfoBar
