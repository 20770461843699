import React, { useState } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

function SearchBox () {
    const history = useHistory()
    const location = useLocation()
    const [assetType, setAssetType] = useState('synchronization')
    const [searchValue, setSearchValue] = useState()
    
    function objectToQueryString(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }
    
    const _doSearch = () => {
        let query = {
          page: 1,
          q: searchValue
        }
      
        if (searchValue === undefined || searchValue === '') {
          query = { page: 1 }
        }
        history.push({
          pathname: `/licenses/${assetType}`,
          search: `?${objectToQueryString(query)}`
        })
    }
    
    const _keyUp = (event) => {
        if (event.keyCode === 13) {
          _doSearch()
        }
    }
    
    const handleChange = (e) => {
        setAssetType(e.target.value)
    }

    return (<>
        {/* Welcome panel */}
        <section aria-labelledby="profile-overview-title">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">Quick Search</h2>
            <div className="mt-3 pr-5 rounded-lg bg-white overflow-hidden shadow">
                <div className="bg-white w-full relative flex items-center justify-between px-2 py-6">
                    <div className="flex-1">

                        <div className="px-2 lg:px-6">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative rounded-md">
                                <div className="absolute inset-y-0 left-0 flex items-center">
                                    <label htmlFor="assetType" className="sr-only">
                                        Asset Type
                                    </label>
                                    <select
                                        onChange={(e) => handleChange(e)}
                                        id="assetType"
                                        name="assetType"
                                        autoComplete="assetType"
                                        value={assetType}
                                        defaultValue={location.pathname.split('/')[1]}
                                        className="focus:ring-transparent focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-gray-100 text-gray-500 sm:text-sm rounded-md"
                                    >
                                        <option value="synchronization">Synchronization</option>
                                        <option value="mechanical">Mechanical</option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyUp={(event) => _keyUp(event)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-200 block w-full pl-40 sm:text-sm border-gray-200 rounded-md"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={() => _doSearch()}
                        className="inline-flex -ml-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                        Search
                    </button>
                </div>
            </div>
        </section>
      </>)
}

export default SearchBox