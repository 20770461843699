import React from 'react';
import { useFormContext } from 'react-hook-form';

const FormError = ({ name }) => {
  const { formState: { errors } } = useFormContext();
  
  let fieldError = errors[name]
  
  if (name.includes('.')) {
    const parts = name.split('.')
    if (parts[0].includes('[')) {
      const indexTemp = parts[0].split('[')
      const indexTemp2 = indexTemp[1].split(']')
      const index = parseInt(indexTemp2[0])
      if (errors?.[indexTemp[0]]) {
        const tempErrors = errors?.[indexTemp[0]][index];
        fieldError = tempErrors?.[parts[1]]
      }
    } else {
      const tempErrors = errors?.[parts[0]];
      fieldError = tempErrors?.[parts[1]]
    }
  }

  if (!fieldError) {
    return null;
  } else {
    return (<>
      {fieldError?.type === 'required' && <p className="mt-2 ml-1 text-xs text-red-500">
        This field is required
      </p>}
    </>);
  }
}

export default FormError;
