import React, { useState, Fragment, useRef } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { AdjustmentsVerticalIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useFormContext } from 'react-hook-form'

import { getComposition, getRecording } from '../../../../api/core'
import { searchExpData } from 'api/core'
import { MECH_DEFAULT_VALUES, TERRITORY_OPTIONS } from 'components/const'

const DataSearchBox = ({ includeRecording = false, resource = "sync", songIndex, songWriters, setSongWriters }) => {
  const [assetType, setAssetType] = useState('compositions')
  const [searchValue, setSearchValue] = useState()
  const [searchField, setSearchField] = useState()
  const [filterType, setFilterType] = useState()
  const [filterField, setFilterField] = useState()

  const [searchResultType, setSearchResultType] = useState()
  const [searchResults, setSearchResults] = useState([])
  const methods = useFormContext()
  const { setValue, reset } = methods

  const popoverRef = useRef()

  const assetTypeOptions = [
    { label: 'Composition', value: 'compositions' },
    ... includeRecording ? [{ label: 'Recording', value: 'recordings' }] : []
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function objectToQueryString(obj) {
    var str = []
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return '?' + str.join('&')
  }

  const populateSong = composition => {
    const shares = composition?.shares
    let controlledWriters = []
    let uncontrolledPublishers = []
    let uncontrolledWriters = []

    setValue(`songs[${songIndex}].title`, composition._title, true)
    setValue(`songs[${songIndex}].exp_composition_code`, composition.external_id, true)

    for (let share of shares) {
      if (
        share?.exploration_platforms &&
        share?.exploration_platforms.length > 0
      ) {
        // Controlled shares
        var tempWriters = share?.writers?.map(writer => {
          return {name: writer?.name, ownership: writer?.ownership}
        })

        setValue(`songs[${songIndex}].custom_id`, share.custom_id, true)

        for (let writer of tempWriters) {
          let expTerritories = []
          if (share.mr_share_territory) {
            if (share.mr_share_territory.includes(',')) {
              share.mr_share_territory.split(',').map((terr, _idx) => {
                expTerritories.push(
                  TERRITORY_OPTIONS.find((element) => {
                    return element.value === terr
                  })
                )
              })
            } else {
              share.mr_share_territory.split(' ').map((terr, _idx) => {
                expTerritories.push(
                  TERRITORY_OPTIONS.find((element) => {
                    return element.value === terr
                  })
                )
              })
            }
          }
          controlledWriters.push({
            name: writer?.name,
            client_name: share?.royalty_code,
            client_code: share?.royalty_code,
            controlled_percentage: writer?.ownership,
            territory: expTerritories
          })
        }
      } else {
        // Uncontrolled shares
        var tempPublishers = share?.collectors?.map(pub => {
          return pub?.name
        })
        for (let pub of tempPublishers) {
          uncontrolledPublishers.push(pub)
        }

        var tempWriters = share?.writers?.map(writer => {
          return writer?.name
        })
        for (let writer of tempWriters) {
          uncontrolledWriters.push(writer)
        }
      }
    }

    let writerList = songWriters.slice(0);
    writerList[songIndex] = controlledWriters
    setSongWriters(writerList)

    setValue(
      `songs[${songIndex}].uncontrolled_publishers`,
      uncontrolledPublishers.join(', '),
      true
    )

    if (uncontrolledWriters?.length > 0) {
      resource === "sync" && setValue(
        `songs[${songIndex}].uncontrolled_info`,
        uncontrolledWriters.join(', '),
        true
      )
      resource === "mech" && setValue(
        `songs[${songIndex}].uncontrolled_writers`,
        uncontrolledWriters.join(', '),
        true
      )
    } else {
      resource === "sync" && setValue(`songs[${songIndex}].uncontrolled_info`, null, true)
      resource === "mech" && setValue(`songs[${songIndex}].uncontrolled_writers`, null, true)
    }
  }

  const populateRecording = (recording, index) => {
    const relatedArtists = recording?.related_artists
    let artists = []

    setValue(`recordings[0].title`, recording.title, true)
    setValue(`recordings[0].client_code`, recording.royalty_code, true)
    setValue(`recordings[0].client_name`, recording.royalty_code, true)
    setValue(`recordings[0].controlled_percentage`, recording.percentage, true)

    for (let artist of relatedArtists) {
      artists.push(artist.name)
    }
    setValue(`recordings[0].controlled_artists`, artists.join(', '), true)
  }

  const handlePullInfo = (type, code) => {
    if (type === 'compositions') {
      getComposition(code)
        .then(res => {
          populateSong(res.data?.data)
        })
        .catch((err) => {
          console.log(err)
          console.log('Error pulling data for', code)
        })
    } else if (type === 'recordings') {
      getRecording(code)
        .then(res => {
          populateRecording(res.data?.data)
        })
        .catch(() => {
          console.log('Error pulling data for', code)
        })
    }
  }

  const _doSearch = () => {
    let query = {
      page: 1,
      q: searchValue
    }

    if (searchValue === undefined || searchValue === '') {
      query = { page: 1 }
    }
    setSearchField('')
    setFilterType('')
    setFilterField('')
    searchExpData(assetType, objectToQueryString(query))
      .then(res => {
        setSearchResultType(assetType)
        setSearchResults(res.data.data.results)
      })
      .catch(err => console.log(err))
  }

  const _keyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      _doSearch()
    }
  }

  const handleChange = e => {
    setAssetType(e.target.value)
    setSearchValue('')
  }

  const handleAdvancedSearch = () => {
    let query = {
      page: 1,
      q: searchField
    }
    if (filterType !== null && filterType !== undefined) {
      let filterQ = filterType + '|' + filterField
      query['f'] = filterQ
    }
    popoverRef.current?.click()
    setSearchValue('')
    searchExpData(assetType, objectToQueryString(query))
      .then(res => {
        setSearchResultType(assetType)
        setSearchResults(res.data.data.results)
      })
      .catch(err => console.log(err))
  }

  const resetAdvancedSearch = () => {
    setSearchField('')
    setFilterType('')
    setFilterField('')
  }

  const placeHolders = {
    compositions: 'Search for a Composition',
    recordings: 'Search for a Recording'
  }

  const options = {
    compositions: {
      writers: 'Writer',
      owners: 'Owner',
      publishers: 'Collector'
    },
    recordings: {
      isrcs: 'IDs',
      artists: 'Artist'
    }
  }

  return (
    <>
      {!includeRecording && (<div className="mt-5 rounded-md bg-indigo-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-indigo-800">Pulling info for song # {songIndex+1}</p>
            </div>
          </div>
        </div>)
      }
      <div className='mt-5 space-y-6'>
        <div className='bg-white shadow rounded-lg px-4 py-5 sm:p-6'>
          <div className='space-y-8 sm:space-y-6'>
            <div className='flex-1'>
              <div className='flex flex-col'>
                <label htmlFor='search' className='sr-only'>
                  Search
                </label>
                <div className='rounded-md'>
                  <input
                    type='text'
                    name='search'
                    id='search'
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    onKeyPress={(e) => _keyPress(e)}
                    className='focus:ring-red-500 focus:border-red-500 disabled:bg-gray-200 block w-full sm:text-sm border-gray-200 rounded-md'
                    placeholder={placeHolders[assetType]}
                  />
                </div>
                <div className='flex flex-row justify-between mt-1'>
                  <div className='rounded-md'>
                    <div className='flex items-center'>
                      <label htmlFor='assetType' className='sr-only'>
                        Asset Type
                      </label>
                      <select
                        onChange={e => handleChange(e)}
                        id='assetType'
                        name='assetType'
                        autoComplete='assetType'
                        defaultValue={'compositions'}
                        className='focus:ring-transparent focus:border-red-500 h-full py-2 pl-3 pr-7 border-transparent bg-gray-100 text-gray-500 cursor-pointer sm:text-sm rounded-md'
                      >
                        {assetTypeOptions.map((item, index) => (
                          <option key={index} value={item.value}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    <Popover className='relative'>
                      {({ open }) => (
                        <>
                          <Popover.Button
                            ref={popoverRef}
                            className={classNames(
                              open ? 'text-gray-900' : 'text-gray-500',
                              'h-full bg-white rounded-md items-center text-base mr-1 font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                            )}
                          >
                            <span>
                              <AdjustmentsVerticalIcon
                                className='h-full w-10 rounded-md bg-red-600 text-white p-2 py-0 hover:bg-red-700'
                                aria-hidden='true'
                              />
                            </span>
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 translate-y-1'
                          >
                            <Popover.Panel className='absolute z-10 transform -translate-x-2/3 mt-3 px-2 w-96 sm:px-0'>
                              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='relative w-full bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                                  <div className='overflow-hidden divide-y divide-gray-200'>
                                    <div className='p-6'>
                                      <div className='pb-2 border-b border-gray-200'>
                                        <h3 className='text-lg leading-6 font-medium text-gray-900'>
                                          Advanced Search
                                        </h3>
                                      </div>
                                      <div className='py-4'>
                                        <div className='grid grid-cols-1 gap-y-6 gap-x-4'>
                                          <div className=''>
                                            <label
                                              htmlFor='about'
                                              className='block text-sm font-medium text-gray-700'
                                            >
                                              {placeHolders[assetType] ||
                                                'Search'}
                                            </label>
                                            <div className='mt-1'>
                                              <textarea
                                                type='text'
                                                name='query'
                                                placeholder='Separate each query with a | (ex: Title 1|Title 2)'
                                                value={searchField}
                                                onChange={e =>
                                                  setSearchField(e.target.value)
                                                }
                                                rows={3}
                                                className='shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md'
                                                defaultValue={''}
                                              />
                                            </div>
                                          </div>

                                          {options[assetType] && (
                                            <div className=''>
                                              <fieldset className=''>
                                                <legend className='block text-sm font-medium text-gray-700'>
                                                  Filter
                                                </legend>
                                                <div className='mt-1 rounded-md shadow-sm -space-y-px'>
                                                  <div>
                                                    <label
                                                      htmlFor='filter'
                                                      className='sr-only'
                                                    >
                                                      Filter
                                                    </label>
                                                    <select
                                                      id='filter'
                                                      name='filter'
                                                      value={filterType}
                                                      onChange={e =>
                                                        setFilterType(
                                                          e.target.value
                                                        )
                                                      }
                                                      className='focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
                                                    >
                                                      <option value='-1'>
                                                        Select...
                                                      </option>
                                                      {Object.keys(
                                                        options[assetType]
                                                      ).map((key, index) => (
                                                        <option
                                                          key={index}
                                                          value={key}
                                                        >
                                                          {
                                                            options[assetType][
                                                              key
                                                            ]
                                                          }
                                                        </option>
                                                      ))}
                                                    </select>
                                                  </div>
                                                  <div>
                                                    <label
                                                      htmlFor='filter-field'
                                                      className='sr-only'
                                                    >
                                                      Filter Field
                                                    </label>
                                                    <input
                                                      type='text'
                                                      name='filter-field'
                                                      id='postal-code'
                                                      value={filterField}
                                                      onChange={e =>
                                                        setFilterField(
                                                          e.target.value
                                                        )
                                                      }
                                                      className='focus:ring-red-500 focus:border-red-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
                                                      placeholder=''
                                                    />
                                                  </div>
                                                </div>
                                              </fieldset>
                                            </div>
                                          )}

                                          <div className='flex justify-end'>
                                            <button
                                              type='button'
                                              className='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                                              onClick={() =>
                                                resetAdvancedSearch()
                                              }
                                            >
                                              Reset
                                            </button>
                                            <button
                                              type='button'
                                              className='ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                              onClick={() =>
                                                handleAdvancedSearch()
                                              }
                                            >
                                              Search
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    <button
                      type='button'
                      onClick={() => _doSearch()}
                      className='items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none'
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='divide-y divide-gray-200'>
              {searchResults.map(item => (
                <div
                  key={item.external_id}
                  className='flex flex-row justify-between py-2'
                >
                  <div>
                    <a
                      href={`http://data.exploration.io/${searchResultType}/${item.external_id}/`}
                      className='mr-1 text-sm text-black-500 hover:text-red-600'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {searchResultType === 'compositions' ? item._title : item.title}
                    </a>
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{item.external_id}</p>
                  </div>
                  <button
                    type='button'
                    className='text-sm text-indigo-700 whitespace-nowrap hover:text-indigo-500'
                    onClick={() =>
                      handlePullInfo(searchResultType, item.external_id)
                    }
                  >
                    Pull Info
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataSearchBox