import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';

import FormLabel from '../../../../components/Form/FormLabel';
import FormError from '../../../../components/Form/FormError';
import DatePicker from '../../../../components/Form/DatePicker';

const LicenseTerms = ({ saveForLater, baseInputStyle }) => {
  const methods = useFormContext();
  const { register, getValues, setValue } = methods;
  const [showTermLengthDatePicker, setShowTermLengthDatePicker] = useState(!getValues('indefinite_term_length'));
  const [paymentReceived, setPaymentReceived] = useState(getValues("payment_received") || false); 

  const handleTermLengthCheckbox = (name) => {
    const val = getValues(name);
    setValue(name, !val, true);
    setShowTermLengthDatePicker(val)
  };

  const handlePaymentReceived = () => {
    setValue('payment_received', !paymentReceived)
    setPaymentReceived(!paymentReceived)
  };

  return (
    <div className='bg-white shadow rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <div>
          <h3 className='text-xl font-medium leading-6 text-gray-900'>
            License Terms
          </h3>
        </div>
        <div className='mt-5'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='License Start Date' name='license_date' required />
              <DatePicker
                name='license_date'
                methods={methods}
                future={5}
                required
              />
              <FormError name='license_date' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='License End Date' name='term_length' required />
              {showTermLengthDatePicker && (
                <DatePicker
                  name='term_length'
                  methods={methods}
                  future={5}
                  required
                />
              )}
              <div className='flex items-start mt-2'>
                <div className='h-5 flex items-center'>
                  <input
                    id={'indefinite_term_length'}
                    name={'indefinite_term_length'}
                    type='checkbox'
                    className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                    {...register('indefinite_term_length')}
                    onChange={() =>
                      handleTermLengthCheckbox('indefinite_term_length')
                    }
                  />
                </div>
                <div className='ml-3 text-sm'>
                  <label
                    htmlFor={'indefinite_term_length'}
                    className='font-normal text-gray-700'
                  >
                    Indefinite
                  </label>
                </div>
              </div>
              <FormError name='term_length' />
            </div>

            <div className='col-span-6'>
              <FormLabel
                label='Licensed Territory'
                name='licensed_territory'
                required
              />
              <input
                type='text'
                name='licensed_territory'
                id='licensed_territory'
                className={baseInputStyle}
                {...register('licensed_territory', { required: !saveForLater })}
              />
              <FormError name='licensed_territory' />
            </div>

            <div className='col-span-3'>
              <FormLabel label='Fee' name='fee' required />
              <input
                type='text'
                name='fee'
                id='fee'
                className={baseInputStyle}
                {...register('fee', { required: !saveForLater })}
              />
              <FormError name='fee' />
            </div>

            <div className='col-span-3'>
              <FormLabel label='Payment Terms' name='payment_terms' required />
              <input
                type='text'
                name='payment_terms'
                id='payment_terms'
                className={baseInputStyle}
                {...register('payment_terms', { required: !saveForLater })}
              />
              <FormError name='payment_terms' />
              <div className="flex items-start mt-2">
                <div className="h-5 flex items-center">
                  <input
                    id={"payment_received"}
                    name={"payment_received"}
                    checked={paymentReceived}
                    value={paymentReceived}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    onChange={() =>
                      handlePaymentReceived()
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={"payment_received"}
                    className="font-normal text-gray-700"
                  >
                    Payment Received?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LicenseTerms
