import React from 'react';

const ProjectInformation = ({ project }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Project Information
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {project ? (<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Project Title</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.title}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.category}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Fee ($)</dt>
              <dd className="mt-1 text-sm text-gray-900">${project?.fee}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Media</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.media}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Territory</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.territory}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Air Date</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.air_date}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Scene Description/Notes</dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{project?.scene_description}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Approval needed from management?</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.approval_needed ? 'Yes' : 'No'}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Email</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_email}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Company</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_company}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Title</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_title}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_phone_number}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Contact Location</dt>
              <dd className="mt-1 text-sm text-gray-900">{project?.contact_location}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-xs font-medium text-gray-500">Request</dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{project?.request}</dd>
            </div>
        </dl>)
        : <p className="text-gray-500">Project information not available.</p>}
      </div>
    </div>
  </section>);
}

export default ProjectInformation;
