import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import FormLabel from '../../../../components/Form/FormLabel'
import FormError from '../../../../components/Form/FormError'
import DatePicker from '../../../../components/Form/DatePicker'
import { classNames } from '../../../../components/utils'

const ProjectInformation = ({ saveForLater, baseInputStyle }) => {
  const methods = useFormContext()
  const {
    register,
    setValue,
    formState,
    setError,
    clearErrors,
    getValues,
    watch
  } = methods

  const handleCheckbox = (evt, name) => {
    const val = getValues(name)
    setValue(name, !val, true)
  }

  return (
    <div className='bg-white shadow px-4 py-5 rounded-lg sm:p-6'>
      <div>
        <h3 className='text-xl font-medium leading-6 text-gray-900'>
          Project Information
        </h3>
      </div>
      <div className='divide-y divide-gray-200'>
        <div className='mt-5'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-4'>
              <FormLabel label='Project Title' name='project.title' required />
              <input
                type='text'
                name='project.title'
                id='project.title'
                className={baseInputStyle}
                {...register('project.title', { required: !saveForLater })}
              />
              <FormError name='project.title' />
            </div>

            <div className='col-span-2'>
              <FormLabel label='Category' name='project.category' required />
              <div className='flex rounded-md shadow-sm'>
                <select
                  id='project.category'
                  name='project.category'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  {...register('project.category', { required: !saveForLater })}
                >
                  <option value='film'>Film</option>
                  <option value='tv'>TV</option>
                  <option value='brand'>Brand</option>
                  <option value='digital'>Digital</option>
                </select>
              </div>
              <FormError name='project.category' />
            </div>

            <div className='col-span-2'>
              <FormLabel label='Fee ($)' name='project.fee' required />
              <input
                type='number'
                min='0'
                step='any'
                name='project.fee'
                id='project.fee'
                className={baseInputStyle}
                {...register('project.fee', { required: !saveForLater })}
              />
              <FormError name='project.fee' />
            </div>

            <div className='col-span-2'>
              <FormLabel label='Media' name='project.media' required />
              <input
                type='text'
                name='project.media'
                id='project.media'
                className={baseInputStyle}
                {...register('project.media', { required: !saveForLater })}
              />
              <FormError name='project.media' />
            </div>

            <div className='col-span-2'>
              <FormLabel label='Territory' name='project.territory' required />
              <input
                type='text'
                name='project.territory'
                id='project.territory'
                className={baseInputStyle}
                {...register('project.territory', { required: !saveForLater })}
              />
              <FormError name='project.territory' />
            </div>

            <div className='col-span-3'>
              <FormLabel label='Air Date' name='project.air_date' required />
              <DatePicker
                name='project.air_date'
                methods={methods}
                future={5}
                required
              />
              <FormError name='project.air_date' />
            </div>

            <div className='col-span-6'>
              <FormLabel
                label='Scene Description/Notes'
                name={`project.scene_description`}
                required
              />
              <textarea
                id={`project.scene_description`}
                name={`project.scene_description`}
                rows={3}
                className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
                {...register(`project.scene_description`, { required: !saveForLater })}
              />
              <FormError name='project.scene_description' />
            </div>

            <div className='col-span-6'>
              <div className='flex items-start mt-2'>
                <div className='h-5 flex items-center'>
                  <input
                    id={'project.approval_needed'}
                    name={'project.approval_needed'}
                    type='checkbox'
                    className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                    {...register('project.approval_needed')}
                    onChange={e => handleCheckbox(e, 'project.approval_needed')}
                  />
                </div>
                <div className='ml-3 text-sm'>
                  <label
                    htmlFor={'project.approval_needed'}
                    className='font-normal text-gray-700'
                  >
                    <FormLabel
                      label='Approval needed from management?'
                      name='project.approval_needed'
                    />
                  </label>
                </div>
              </div>
              <FormError name='project.approval_needed' />
            </div>
          </div>
        </div>
        <div className='mt-5 pt-5'>
          <div>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>
              Clearance Contact
            </h2>
          </div>
          <div className='mt-5 grid grid-cols-6 gap-6'>
            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Name' name='project.contact_name' required />
              <input
                type='text'
                name='project.contact_name'
                className={baseInputStyle}
                {...register('project.contact_name', { required: !saveForLater })}
              />
              <FormError name='project.contact_name' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Email' name='project.contact_email' required />
              <input
                type='email'
                name='project.contact_email'
                className={baseInputStyle}
                {...register('project.contact_email', { required: !saveForLater })}
              />
              <FormError name='project.contact_email' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Company' name='project.contact_company' />
              <input
                type='text'
                name='project.contact_company'
                className={baseInputStyle}
                {...register('project.contact_company')}
              />
              <FormError name='project.contact_company' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Title' name='project.contact_title' />
              <input
                type='text'
                name='project.contact_title'
                className={baseInputStyle}
                {...register('project.contact_title')}
              />
              <FormError name='project.contact_title' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Phone' name='project.contact_phone_number' />
              <input
                type='text'
                name='project.contact_phone_number'
                className={baseInputStyle}
                {...register('project.contact_phone_number')}
              />
              <FormError name='project.contact_phone_number' />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <FormLabel label='Location' name='project.contact_location' />
              <input
                type='text'
                name='project.contact_location'
                className={baseInputStyle}
                {...register('project.contact_location')}
              />
              <FormError name='project.contact_location' />
            </div>
          </div>
        </div>
        <div className='mt-5 pt-5'>
          <div>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>
              Request Information
            </h2>
          </div>
          <div className='mt-5 grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              <FormLabel label='Request' name={`project.request`} required />
              <textarea
                id={`project.request`}
                name={`project.request`}
                rows={3}
                className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
                {...register(`project.request`, { required: !saveForLater })}
              />
              <FormError name='project.request' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectInformation
