import React from 'react';
import { capitalizeWords } from 'utils';

const SongInformation = ({ songs }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Song Information
        </h2>
      </div>
        {songs.map((song, index) => console.log(song) || (
          <div key={index} className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl key={index} className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-xs font-medium text-gray-500">Controlled?</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.controlled ? 'Yes' : 'No'}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Song Title</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.title}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Composition Code</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.exp_composition_code}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Custom ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{song?.custom_id}</dd>
              </div>
              {song?.controlled_writer_list?.map((writer, index) => {
                return(<>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Controlled Writer {index+1}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.name}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Client Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.client_name}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Client Code</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.client_code}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-xs font-medium text-gray-500">Controlled %</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.controlled_percentage}</dd>
                  </div>
                  <div className="sm:col-span-2 mb-5">
                    <dt className="text-xs font-medium text-gray-500">Territory</dt>
                    <dd className="mt-1 text-sm text-gray-900">{writer?.territory}</dd>
                  </div>
                </>)
              })
              }
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Uncontrolled Publishers</dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{song?.uncontrolled_publishers}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Uncontrolled Writers</dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{song?.uncontrolled_info}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Credits</dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{song?.credits}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {song?.status && (
                    <span key={song.status} className="mr-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {capitalizeWords(song.status)}
                    </span>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        ))}
    </div>
  </section>);
}

export default SongInformation;
