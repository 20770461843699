import React from 'react';

import { timeToMinutesAndSeconds } from 'components/utils';
import { capitalizeWords } from 'utils';

const ReleaseInformation = ({ release }) => {
  const { minutes: recordingLengthMinutes, seconds: recordingLengthSeconds } = timeToMinutesAndSeconds(release?.recording_length)

  const FormatsOfReleases = [
    { label: 'Digital Download', value: 'digital_download' },
    { label: 'Streaming', value: 'streaming' },
    { label: 'Vinyl', value: 'vinyl' },
    { label: 'CD', value: 'cd' },
    { label: 'Cassette', value: 'cassette' },
    { label: '8-Track', value: '8_track' },
    { label: 'Other', value: 'other' }
  ];
  
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Release Information
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {release ? (<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Release Title</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.title}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Project Code</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.project_code}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Recording Artist</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.recording_artist}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Record Label</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.record_label}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Distributor</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.distributor}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">ISRC</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.isrc}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">UPC</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.upc}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Recording Length</dt>
              <dd className="mt-1 text-sm text-gray-900">{recordingLengthMinutes}:{recordingLengthSeconds}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Release Date</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.release_date}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Release Type</dt>
              <dd className="mt-1 text-sm text-gray-900">{capitalizeWords(release?.release_type)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Release Territory</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.release_territory}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Format/s of Release</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {release?.formats && (release?.formats?.split(',')).map((format) => {
                  return (<span key={format} className="mr-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  {FormatsOfReleases.find((element) => {return element.value === format}).label}
                  {format === 'other' && `: ${release?.other_format}`}
                </span>)
                })}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Platform of Release</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.other_platform}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Manufacture Date</dt>
              <dd className="mt-1 text-sm text-gray-900">{release?.manufacture_date}</dd>
            </div>
            {release?.other_platform?.toLowerCase() === 'physical' && (<div className="sm:col-span-2">
                <dt className="text-xs font-medium text-gray-500">If Physical Release, how many are being created?</dt>
                <dd className="mt-1 text-sm text-gray-900">{release?.physical_units}</dd>
              </div>)
            }
        </dl>)
        : <p className="text-gray-500">Release information not available.</p>}
      </div>
    </div>
  </section>);
}

export default ReleaseInformation;
