import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from 'routes/Router';
import { AuthContext } from 'stores/auth';

import './styles/index.css';

function App () {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authStore.hasToken()) {
      authStore.fetchUser().then(() => {
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authStore]);

  return (
    <BrowserRouter>
      <Router loading={loading} />
    </BrowserRouter>
  );
};

export default App;
