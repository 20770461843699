import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'

import { AuthContext } from 'stores/auth'

import PublicRoute from './PublicRoute'
import ProtectedRoutes from './ProtectedRoutes'
import BlankWrapper from 'containers/BlankWrapper'

import LoadingPage from './LoadingPage'
import Home from './Home'
import LogIn from './Auth/LogIn'
// import Register from './Auth/Register'
// import RegisterSuccess from './Auth/Register/Success'
// import ForgotPassword from './Auth/Password/ForgotPassword'
// import ResetPassword from './Auth/Password/ResetPassword'
// import VerifyEmail from './Auth/VerifyEmail'

import Mechanical from './Mechanical/Create';

function Router ({ loading }) {
  const authStore = useContext(AuthContext);

  return useObserver(() => {
    if (loading) {
      return (<LoadingPage />)
    }

    if (!authStore.loggedIn) {
      return (<main className="h-full">
        <Switch>
          <PublicRoute exact path="/login" component={LogIn} layout={BlankWrapper} />
          {/* <PublicRoute exact path="/register" component={Register} layout={BlankWrapper} /> */}
          {/* <PublicRoute exact path="/register/success/" component={RegisterSuccess} layout={BlankWrapper} /> */}

          {/* <PublicRoute path="/password/forgot/" component={ForgotPassword} layout={BlankWrapper} />
          <PublicRoute path="/password/reset/" component={ResetPassword} layout={BlankWrapper} />
          <PublicRoute path="/account/verify/:verify_token/" component={VerifyEmail} layout={BlankWrapper} /> */}

          <PublicRoute exact path="/" component={LogIn} layout={BlankWrapper} />
          {/* <Route path="/" component={AuthenticatedRoutes} /> */}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </main>)
    } else {
      return (<main className="h-full">
        <Switch>
          <Route path="/" component={ProtectedRoutes} />
        </Switch>
      </main>)
    }
  })


}

export default Router
