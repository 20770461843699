import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';

import { saveSynchronizationLicense } from '../../../api/core';
import { buildFormData } from 'utils';
import { classNames } from '../../../components/utils';
import Form from './components/Form';
import { SYNC_DEFAULT_VALUES } from 'components/const';
import DataSearchBox from './components/DataSearchBox';
import Errors from 'components/Form/Errors';

const Synchronization = () => {
  let history = useHistory();
  const methods = useForm({
    defaultValues: SYNC_DEFAULT_VALUES,
    mode: 'onBlur'
  });
  const { handleSubmit, reset, control, setError, formState: { errors, isDirty, isValid } } = methods;
  const [saving, setSaving] = useState(false);
  const [songIndex, setSongIndex] = useState(0);
  const [songWriters, setSongWriters] = useState([]);
  const [saveForLater, setSaveForLater] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit = async (data) => {
    setSaving(true);
    setFormErrors([]);
    setShowErrors(false);

    if (saveForLater) {
      data.save_for_later = true;
    } else {
      data.save_for_later = false;
    }

    if (!data.project?.fee) {
      delete data['project']['fee']
    }

    if (data.songs) {
      for (let song of data.songs) {
        if (song.controlled_writers) {
          for (let writer of song.controlled_writers) {
            if (writer.territory) {
              if (Array.isArray(writer['territory'])) {
                if (writer['territory'][0] !== undefined) {
                  writer['territory'] = (writer['territory'].map((terr) => { return terr.value })).join(',')
                } else {
                  delete writer['territory']
                }
              } else {
                writer['territory'] = writer['territory']['value']
              }
            } else {
              delete writer['territory']
            }
    
            if (!writer.controlled_percentage) {
              delete writer['controlled_percentage']
            }
          }
        }
      }
    }

    if (data.indefinite_term_length) {
      data.term_length = null
    }

    // NOTE: Transform data to FormData to allow file attachments
    const newData = buildFormData(data);

    await saveSynchronizationLicense(newData).then(res => {
      history.push('/');
      setSaving(false);
    }).catch(err => {
      console.log(err.response.data);
      setFormErrors(err.response.data.all_errors);
      setShowErrors(true);
      setSaving(false);
    });
  }

  const onError = (errors) => {
    console.log(errors);
  };

  return (<FormProvider { ...methods }>
    <form action="#" method="POST" onSubmit={handleSubmit(onSubmit, onError)} className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 mb-5">
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">Create Synchronization License</h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => reset()}
              >
                Reset
              </button>
              <button
                  type="submit"
                  onClick={() => setSaveForLater(true)}
                  className={classNames(
                    saving
                      ? "text-gray-600 bg-gray-200 border-gray-300 hover:bg-gray-200 focus:ring-gray-500"
                      : "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
                    "ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  )}
                  disabled={saving}
                >
                  Save For Later
              </button>
              <button
                type="submit"
                onClick={() => setSaveForLater(false)}
                className={classNames(
                  'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
                  "ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 "
                )}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {showErrors && <Errors errors={formErrors} />}

      <div className="grid grid-cols-1 gap-6 items-start lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="lg:col-start-1 lg:col-span-2">
          <Form saveForLater={saveForLater} saving={saving} songIndex={songIndex} setSongIndex={setSongIndex} songWriters={songWriters} />
        </div>
        <div className='sticky top-0 lg:col-start-3 lg:col-span-1'>
          <DataSearchBox includeRecording={true} songIndex={songIndex} songWriters={songWriters} setSongWriters={setSongWriters} />
        </div>
      </div>

      <div className="mt-6 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => reset()}
        >
          Reset
        </button>
        <button
          type="submit"
          onClick={() => setSaveForLater(true)}
          className={classNames(
            saving
              ? "text-gray-600 bg-gray-200 border-gray-300 hover:bg-gray-200 focus:ring-gray-500"
              : "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
            "ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 "
          )}
          disabled={saving}
        >
          Save For Later
        </button>
        <button
          type="submit"
          onClick={() => setSaveForLater(false)}
          className={classNames(
            (saving)
              ? 'text-gray-600 bg-gray-200 border-gray-300 hover:bg-gray-200 focus:ring-gray-500'
              : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
            "ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 "
          )}
          disabled={saving}
        >
          Save
        </button>
      </div>
    </form>
  </FormProvider>);
}

export default Synchronization;
