import React from "react"
import { useFormContext, Controller } from "react-hook-form"

import FormLabel from "../../../../components/Form/FormLabel"
import FormError from "../../../../components/Form/FormError"
import { TERRITORY_OPTIONS } from "components/const"
import SelectField from "components/SelectField"

const ControlledWriterForm = ({
  saveForLater,
  baseInputStyle,
  songIndex,
  writer,
  index,
  remove,
  fields,
}) => {
  const methods = useFormContext()
  const { register, control, setValue } = methods

  return (
    <div className="py-2 mt-2">
      <div className="flex flex-row justify-between col-span-9 -mb-2">
        <div className="text-sm col-span-6">Writer #{index + 1}</div>

        {fields.length > 1 && (
          <button
            className="btn btn-link btn-remove text-sm text-gray-500"
            type="button"
            onClick={() => remove(index)}
          >
            Remove
          </button>
        )}
      </div>
      <div className="mt-5 grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6">
          <FormLabel
            label="Writer Name"
            name={`songs[${songIndex}].controlled_writers[${index}].name`}
            required
          />
          <input
            type="text"
            name={`controlled_writers[${index}].name`}
            className={baseInputStyle}
            {...register(`songs[${songIndex}].controlled_writers[${index}].name`, {
              required: !saveForLater,
            })}
          />
          <FormError name={`songs[${songIndex}].controlled_writers[${index}].name`} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <FormLabel
            label="Client Name"
            name={`songs[${songIndex}].controlled_writers[${index}].client_name`}
          />
          <input
            type="text"
            name={`songs[${songIndex}].controlled_writers[${index}].client_name`}
            className={baseInputStyle}
            {...register(`songs[${songIndex}].controlled_writers[${index}].client_name`)}
          />
          <FormError name={`songs[${songIndex}].controlled_writers[${index}].client_name`} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <FormLabel
            label="Client Code"
            name={`songs[${songIndex}].controlled_writers[${index}].client_code`}
          />
          <input
            type="text"
            name={`songs[${songIndex}].controlled_writers[${index}].client_code`}
            className={baseInputStyle}
            {...register(`songs[${songIndex}].controlled_writers[${index}].client_code`)}
          />
          <FormError name={`songs[${songIndex}].controlled_writers[${index}].client_code`} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <FormLabel
            label="Controlled %"
            name={`songs[${songIndex}].controlled_writers[${index}].controlled_percentage`}
          />
          <input
            type="number"
            min="0"
            step="any"
            name={`songs[${songIndex}].controlled_writers[${index}].controlled_percentage`}
            className={baseInputStyle}
            {...register(`songs[${songIndex}].controlled_writers[${index}].controlled_percentage`)}
          />
          <FormError
            name={`songs[${songIndex}].controlled_writers[${index}].controlled_percentage`}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <FormLabel
            label="Territory"
            name={`songs[${songIndex}].controlled_writers[${index}].territory`}
          />
          <Controller
            control={control}
            name={`songs[${songIndex}].controlled_writers[${index}].territory`}
            render={({ field: { value } }) => (
              <SelectField
                defaultValue={writer.territory}
                options={TERRITORY_OPTIONS}
                key={`songs[${songIndex}].controlled_writers[${index}].territory`}
                value={value}
                onChange={(e) => {
                  setValue(`songs[${songIndex}].controlled_writers[${index}].territory`, e)
                }}
                isMulti
              />
            )}
          />
          <FormError name={`songs[${songIndex}].controlled_writers[${index}].territory`} />
        </div>
      </div>
    </div>
  )
}

export default ControlledWriterForm
