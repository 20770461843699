import React from 'react';

const LicenseInformation = ({ license }) => {
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          License Information
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">License ID</dt>
            <dd className="mt-1 text-sm text-gray-900">SYNC-{license.license_id}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Right Type</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {license.right_type === 'publishing' && 'Publishing'}
              {license.right_type === 'sound_recording' && 'Sound Recording'}
              {license.right_type === 'both' && 'Both'}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </section>);
}

export default LicenseInformation;
