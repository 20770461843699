import React, { useEffect } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import FormLabel from '../../../../components/Form/FormLabel'
import FormError from '../../../../components/Form/FormError'
import RenderWriters from 'routes/Mechanical/Create/components/RenderWriters'

const baseInputStyle =
  'mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'

const SongForm = ({ saveForLater, required, songWriters }) => {
  const methods = useFormContext()
  const { register, setValue, getValues, control } = methods

  const handleCheckbox = (evt, name) => {
    const val = getValues(name);
    setValue(name, !val, true);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'songs',
    keyName: 'none'
  })

  return (
    <>
      <div className='pt-6'>
        <div>
          <h3 className='text-xl font-medium leading-6 text-gray-900'>
            Song Information
          </h3>
          <p className='mt-1 text-sm text-gray-500'>
            This section pulls information from{' '}
            <a
              href='https://data.exploration.io'
              className='text-blue-500'
              target='_blank'
              rel='noreferrer'
            >
              Exploration Data
            </a>
          </p>
        </div>

        {fields.map((song, index) => (
          <>
            <div key={index} className='mt-4 grid grid-cols-9 gap-6'>
              <div className="col-span-6">
                <div className="flex items-start mt-2">
                  <div className="h-5 flex items-center">
                    <input
                      id={`songs[${index}].controlled`}
                      name={`songs[${index}].controlled`}
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      {...register(`songs[${index}].controlled`)}
                      onChange={(e) => handleCheckbox(e, `songs[${index}].controlled`)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor={`songs[${index}].controlled`} className="font-normal text-gray-700">
                      <FormLabel label='Controlled?' name={`songs[${index}].controlled`} />
                    </label>
                  </div>
                </div>
                <FormError name={`songs[${index}].controlled`} />
              </div>

              <div className='col-span-9'>
                <FormLabel
                  label='Song Title'
                  name={`songs[${index}].title`}
                  required={required}
                />
                <input
                  type='hidden'
                  name={`songs[${index}].id`}
                  id={`songs[${index}].id`}
                  {...register(`songs[${index}].id`)}
                />
                <input
                  type='text'
                  name={`songs[${index}].title`}
                  id={`songs[${index}].title`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].title`, { required })}
                />
                <FormError name={`songs[${index}].title`} />
              </div>

              <div className="col-span-3">
                <FormLabel label='Composition Code' name={`songs[${index}].exp_composition_code`} />
                <input
                  type="text"
                  name={`songs[${index}].exp_composition_code`}
                  id={`songs[${index}].exp_composition_code`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].exp_composition_code`)}
                />
                <FormError name={`songs[${index}].exp_composition_code`} />
              </div>

              <div className="col-span-6">
                <FormLabel
                  label="Custom ID"
                  name={`songs[${index}].custom_id`}
                />
                <input
                  type="text"
                  name={`songs[${index}].custom_id`}
                  id={`songs[${index}].custom_id`}
                  className={baseInputStyle}
                  {...register(`songs[${index}].custom_id`)}
                />
                <FormError name={`songs[${index}].custom_id`} />
              </div>

              <div className="col-span-12">
                <RenderWriters saveForLater={saveForLater} songIndex={index} songWriters={songWriters} /> 
              </div>

              <div className='col-span-12'>
                <FormLabel
                  label='Status'
                  name={`songs[${index}].status`}
                  required={required}
                />
                <select
                  id={`songs[${index}].status`}
                  name={`songs[${index}].status`}
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  {...register(`songs[${index}].status`, { required })}
                >
                  <option value='pitched'>Pitched</option>
                  <option value='cleared'>Cleared</option>
                  <option value='licensed'>Licensed</option>
                  <option value='quoted'>Quoted</option>
                  <option value='requested'>Requested</option>
                </select>
                <FormError name={`songs[${index}].status`} />
              </div>

              <div className='col-span-6'>
                <FormLabel
                  label='Uncontrolled Publishers'
                  name={`songs[${index}].uncontrolled_publishers`}
                />
                <textarea
                  id={`songs[${index}].uncontrolled_publishers`}
                  name={`songs[${index}].uncontrolled_info`}
                  rows={3}
                  className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
                  {...register(`songs[${index}].uncontrolled_publishers`)}
                />
              </div>

              <div className='col-span-6'>
                <FormLabel
                  label='Uncontrolled Writers'
                  name={`songs[${index}].uncontrolled_info`}
                />
                <textarea
                  id={`songs[${index}].uncontrolled_info`}
                  name={`songs[${index}].uncontrolled_info`}
                  rows={3}
                  className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
                  {...register(`songs[${index}].uncontrolled_info`)}
                />
              </div>

              <div className='col-span-6'>
                <FormLabel label='Credits' name={`songs[${index}].credits`} />
                <textarea
                  id={`songs[${index}].credits`}
                  name={`songs[${index}].credits`}
                  rows={3}
                  className='shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md bg-white focus:ring-0 focus:border-gray-300'
                  {...register(`songs[${index}].credits`)}
                />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default SongForm
